<template>
  <div>
    <map-con style="filter: blur(3px)" />
    <div class="header_l">
      <div class="logo">
        <img src="/images/logo.png" alt="logo" />
      </div>
      <div class="tit">黄岛油港区</div>
      <div class="nav">
        <div class="item active" @click="handleGo('/forecast')">潮流预报</div>
        <div class="item" @click="handleGo('/antiCollision')">监控预警</div>
        <div class="item" @click="handleGo('/d90')">90泊位预报</div>
      </div>
    </div>
    <div class="header_r">
      <div class="tit">董家口港区</div>
      <div class="nav">
        <div class="item" @click="handleGo('/d12')">D12泊位预报</div>
      </div>
    </div>

    <div class="container">
      <div class="warn-area">
        <div class="warn-head">
          <div class="warn-head-title">潮流预报信息查询</div>
        </div>

        <el-form size="small" label-width="100px">
          <el-form-item label="点位">
            <el-select v-model="pid" placeholder="点位" clearable>
              <el-option
                v-for="item in pidList"
                :key="item.pid"
                :label="item.name"
                :value="item.pid"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="流速">
            <el-input
              v-model="start_speed"
              placeholder="请输入开始流速"
              class="selWidth"
              size="small"
              clearable
              style="width: 200px"
            />
            -
            <el-input
              v-model="end_speed"
              placeholder="请输入结束流速"
              class="selWidth"
              size="small"
              clearable
              style="width: 200px"
            />
            (m/s)
          </el-form-item>
          <el-form-item label="水位">
            <el-input
              v-model="start_waterlevel"
              placeholder="请输入起始水位"
              class="selWidth"
              size="small"
              clearable
              style="width: 200px"
            />
            -
            <el-input
              v-model="end_waterlevel"
              placeholder="请输入结束水位"
              class="selWidth"
              size="small"
              clearable
              style="width: 200px"
            />
            (起算面为平均海平面)
          </el-form-item>
          <el-form-item
            label="时间"
            style="display: inline-block; margin-right: 20px"
          >
            <el-date-picker
              v-model="endDate"
              type="datetimerange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              @change="changeDate"
            />
          </el-form-item>

          <el-button
            style="margin-left: 30px"
            size="small"
            type="primary"
            @click="search"
            >搜索</el-button
          >
        </el-form>

        <div class="warn-list" v-if="warnList.length > 0">
          <div class="line l t"></div>
          <div class="line r t"></div>
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
          <div
            class="warn-list-item"
            v-for="(item, index) in warnList"
            :key="index"
          >
            <div class="text">{{ getNowDate(item.cdate) }}</div>
            <div class="text">流速{{ Number(item.speed).toFixed(2) }}m/s</div>
            <div class="text">流向{{ Math.trunc(item.flowto) }}°</div>
            <div class="text">点位C{{ item.pid }}</div>
            <div class="text">水位{{ Number(item.waterlevel).toFixed(2) }}m</div>
          </div>
        </div>
        <div class="warn-pagination" v-if="warnList.length > 0">
          <el-pagination
            background
            layout="prev, pager, next"
            prev-text=" "
            next-text=" "
            :page-size="PageSize"
            :current-page="page"
            :total="total"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>

    <div class="login-area">
      <div class="user">
        用户：{{ account
        }}<i class="el-icon-arrow-down" style="color: #3396de"></i>
      </div>
      <div class="dropdown">
        <div class="dropdown-item" @click="handleGo('/reset')">修改密码</div>
        <div class="dropdown-horizontal"></div>
        <div class="dropdown-item" @click="exit">退出登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import MapCon from "@/components/map";
import axios from "axios";
export default {
  name: "WarnInfo",
  components: {
    MapCon,
  },
  data() {
    return {
      account: this.$utils.getItem("account"),
      PageSize: 5,
      total: 0,
      warnList: [],
      page: 1,
      pid: "",
      start_cdate: "",
      end_cdate: "",
      start_speed: "",
      end_speed: "",
      start_waterlevel: "",
      end_waterlevel: "",
      endDate: [],
      pidList: [
        { pid: 1, name: "c1" },
        { pid: 2, name: "c2" },
        { pid: 3, name: "c3" },
        { pid: 4, name: "c4" },
        { pid: 5, name: "c5" },
        { pid: 6, name: "c6" },
        { pid: 7, name: "c7" },
        { pid: 8, name: "c8" },
      ],
    };
  },
  mounted() {
    // this.getWarnInfo();
  },
  methods: {
    search() {
      this.page = 1;
      this.getWarnInfo();
    },
    getWarnInfo() {
      axios({
        url: this.$C.HTTP_REQUEST_URL + "/api/warnShip/list_page",
        method: "get",
        headers: {
          Authorization: "Bearer " + this.$utils.getItem("X-Admin-Token"),
        },
        params: {
          page: this.page,
          limit: this.PageSize,
          pid: this.pid,
          start_cdate: this.start_cdate,
          end_cdate: this.end_cdate,
          start_speed: this.start_speed,
          end_speed: this.end_speed,
          start_waterlevel: this.start_waterlevel,
          end_waterlevel: this.end_waterlevel,
        },
      }).then((res) => {
        this.total = res.data.data.count;
        this.warnList = res.data.data.list;
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getWarnInfo();
    },
    handleGo(path) {
      this.$router.push({ path });
      var antiCollision = this.$route.fullPath;
      if (antiCollision == "/antiCollision") {
        window.location.reload();
      }
      var warn = this.$route.fullPath;
      if (warn == "/warn") {
        window.location.reload();
      }
    },
    exit() {
      this.$utils.clearItem();
      this.$router.push({ path: "/login" });
    },
    changeDate(e) {
      if (!e == !1) {
        this.start_cdate = e[0];
        this.end_cdate = e[1];
      } else {
        this.start_cdate = "";
        this.end_cdate = "";
      }
    },
    getNowDate(date) {
      var nowDate = new Date(date);
      var nowYear = nowDate.getFullYear();
      var nowMonth = nowDate.getMonth();
      var nowDay = nowDate.getDate();
      var nowHour = nowDate.getHours();
      var nowMinutes = nowDate.getMinutes();
      nowMonth = nowMonth + 1;
      nowMonth = nowMonth.toString().padStart(2, "0");
      nowDay = nowDay.toString().padStart(2, "0");
      nowHour = nowHour.toString().padStart(2, "0");
      nowMinutes = nowMinutes.toString().padStart(2, "0");
      nowMinutes = Math.floor(nowMinutes / 10)
        .toString()
        .padEnd(2, "0");
      return `${nowYear}-${nowMonth}-${nowDay} ${nowHour}:${nowMinutes}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding-top: 110px;
  position: relative;
  z-index: 2000;
}

.header_l,
.header_r {
  z-index: 20000;
  position: fixed;
  top: 30px;
  left: 30px;
  height: 52px;
  background-color: #fff;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    padding-left: 18px;
    img {
      width: 226px;
    }
  }
  .tit {
    font-size: 24px;
    margin: 0 10px;
    font-family: cursive;
    font-weight: bold;
  }
  .nav {
    display: flex;
    align-items: center;
    justify-content: center;
    .item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 52px;
      border-radius: 30px;
      font-size: 16px;
      color: #2a3537;
      cursor: pointer;
      &.active {
        background-color: #3396de;
        color: #fff;
      }
    }
  }
}
.header_l {
  left: 30px;
}
.header_r {
  left: 900px;
  .tit {
    margin-left: 16px;
  }
}
.el-main {
  background-color: #e9eef3;
}
.el-footer {
  height: 40px;
}
.tag-content {
  z-index: 2000;
  position: relative;
  height: 40px;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .tag-logo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  .tag-title {
    font-size: 20px;
    color: #3666b8;
  }
}
.warn-area {
  margin: 0 auto;
  .warn-head {
    max-width: 1300px;
    margin: 0 auto;
    .warn-head-title {
      font-size: 20px;
      color: #2a3537;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
  .warn-list {
    max-width: 1300px;
    margin: 0 auto;
    padding: 40px 70px;
    border-top: 2px solid rgba(113, 216, 242, 0.4);
    border-bottom: 2px solid rgba(113, 216, 242, 0.4);

    .warn-list-item {
      max-width: 1300px;
      height: 98px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 30px;
      box-sizing: border-box;
      margin-bottom: 16px;
      background: rgba(151, 189, 231, 0.6);
      border-left: 6px solid #3396de;
      &.active {
        border-left: 6px solid #ee191f;
      }
      .img {
        width: 200px;
        height: 98px;
        img {
          width: 200px;
          height: 98px;
        }
      }
      .text {
        font-size: 16px;
        padding: 0 10px;
        color: #fff;
        opacity: 0.8;
      }
    }
  }
  .warn-pagination {
    max-width: 1300px;
    margin: 20px auto;
    text-align: right;
  }
}

.login-area {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 2000;
  display: flex;
  flex-direction: column;

  &:hover {
    .dropdown {
      display: inline-flex;
    }
  }

  .dropdown {
    display: none;
    justify-content: flex-start;
    flex-direction: column;
    margin: 10px auto 0;
    border-radius: 6px;
    padding: 6px;
    box-shadow: 0 3px 14px 2px rgba(0, 0, 0, .05), 0 8px 10px 1px rgba(0, 0, 0, 6%), 0 5px 5px -3px rgba(0, 0, 0, 10%);
    background-color: #fff;
    font-size: 14px;
    color: #2a3537;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -20px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom: 10px solid #fff;
    }

    .dropdown-item {
      padding: 4px 10px;
      text-align: center;
      cursor: pointer;
      &:hover {
        background-color: #f3f3f3;
        color: #3396de;
      }
    }
    .dropdown-horizontal {
      height: 0.5px;
      margin: 2px 0;
      background-color: #ccc;
    }
  }

  .user {
    background: #fff;
    padding: 10px 16px;
    border-radius: 22px;
    color: #2a3537;
  }
}
</style>

<style lang="scss">
.el-pagination.is-background .el-pager li {
  min-width: 40px !important;
  height: 40px !important;
  font-size: 16px;
  border: 2px solid #5c6373;
  border-radius: 40px !important;
  font-weight: initial;
  line-height: 36px;
  background-color: rgba(0, 0, 0, 0) !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #3396de !important;
  color: #fff;
  border: 2px solid #3396de;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  background: #3396de !important;
  color: #fff !important;
  border: 2px solid #3396de;
}
.el-pagination button,
.el-pagination span:not([class*="suffix"]) {
  height: 40px;
  line-height: 40px;
}
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: none !important;
  margin: 0px 5px !important;
}
.el-pagination.is-background .btn-prev span {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-right: 12px solid #5c6373;
  border-bottom: 10px solid transparent;
  min-width: auto;
}
.el-pagination.is-background .btn-next span {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 12px solid #5c6373;
  border-bottom: 10px solid transparent;
  min-width: auto;
}
</style>
