<template>
  <div
    class="map-layout"
    style="background: url(/images/223d.png) no-repeat 100%;background-size: cover;"
  >
    <div class="header_l">
      <div class="logo">
        <img src="/images/logo.png" alt="logo" />
      </div>
      <div class="tit">黄岛油港区</div>
      <div class="nav">
        <div class="item" @click="handleGo('/forecast')">{{ $t('navName.forecast') }}</div>
        <div class="item active">{{ $t('navName.antiCollision') }}</div>
        <div class="item" @click="handleGo('/d90')">90{{ $t('berthPrediction') }}</div>
      </div>
    </div>
    <div class="header_r">
      <div class="tit">董家口港区</div>
      <div class="nav">
        <div class="item" @click="handleGo('/d12')">D12{{ $t('berthPrediction') }}</div>
      </div>
    </div>

    <div class="container">
      <div id="divPlugin"></div>

      <el-row class="camera-area">
        <el-col class="camera-col" :span="4">
          <div class="camera-head">
            {{ $t('Lidar') }}1
          </div>
          <div class="camera-box">
            <div class="camera-bar">
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
            </div>
            <div class="camera-con">
              <img v-if="larsers1" :src="larsers1" />
              <img v-else style="width: 110px; height: 110px; margin: 0 auto;" src="/images/testimg3.gif" />
            </div>
            <div class="camera-bar">
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
            </div>
          </div>
        </el-col>
        <el-col class="camera-col" :span="4">
          <div class="camera-head">
            {{ $t('Lidar') }}2
          </div>
          <div class="camera-box">
            <div class="camera-bar">
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
            </div>
            <div class="camera-con">
              <img v-if="larsers2" :src="larsers2" />
              <img v-else style="width: 110px; height: 110px; margin: 0 auto;" src="/images/testimg3.gif" />
            </div>
            <div class="camera-bar">
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
            </div>
          </div>
        </el-col>
        <el-col class="camera-col" :span="4">
          <div class="camera-head">
            {{ $t('Lidar') }}3
          </div>
          <div class="camera-box">
            <div class="camera-bar">
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
            </div>
            <div class="camera-con">
              <img v-if="larsers3" :src="larsers3" />
              <img v-else style="width: 110px; height: 110px; margin: 0 auto;" src="/images/testimg3.gif" />
            </div>
            <div class="camera-bar">
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
            </div>
          </div>
        </el-col>
        <el-col class="camera-col" :span="4">
          <div class="camera-head">
            {{ $t('Lidar') }}4
          </div>
          <div class="camera-box">
            <div class="camera-bar">
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
            </div>
            <div class="camera-con">
              <img v-if="larsers4" :src="larsers4" />
              <img v-else style="width: 110px; height: 110px; margin: 0 auto;" src="/images/testimg3.gif" />
            </div>
            <div class="camera-bar">
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
            </div>
          </div>
        </el-col>
        <el-col class="camera-col" :span="4">
          <div class="camera-head">
            {{ $t('Lidar') }}5
          </div>
          <div class="camera-box">
            <div class="camera-bar">
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
            </div>
            <div class="camera-con">
              <img v-if="larsers5" :src="larsers5" />
              <img v-else style="width: 110px; height: 110px; margin: 0 auto;" src="/images/testimg3.gif" />
            </div>
            <div class="camera-bar">
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
              <div class="camera-rhomb"></div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="bottom">
        <el-row style="z-index: 2000">
          <el-col :span="2">
            <div style="min-height: 20px"></div>
          </el-col>
          <el-col :span="10">
            <div class="warn-area">
              <div class="warn-head">
                <div class="warn-head-title">{{ $t('information') }}</div>
                <div class="warn-head-more" @click="handleGo('/warn')">
                  <span v-for="i in 3" />
                </div>
              </div>
              <div class="warn-info">
                <div
                  class="warn-info-item"
                  v-for="(item, index) in warnList"
                  :key="index"
                >
                  <div class="img">
                    <img v-if="item.larsers_id ==1" :src="larsers1" />
                    <img v-else-if="item.larsers_id ==2" :src="larsers2" />
                    <img v-else-if="item.larsers_id ==3" :src="larsers3" />
                    <img v-else-if="item.larsers_id ==4" :src="larsers4" />
                    <img v-else-if="item.larsers_id ==5" :src="larsers5" />
                    <img v-else src="/images/testimg3.png" />
                  </div>
                  <div class="text">雷达{{ item.larsers_id }}</div>
                  <div class="text">{{ item.date }}</div>
                  <div class="text">距离岸边{{ Number(item.mindistence).toFixed(2) }}米</div>
                  <div class="text">船速为{{ Number(item.speed).toFixed(2) }}m/s</div>
                  <div class="text">
                    <template v-if="item.acceleration == null ">加速度为0m/s²</template>
                    <template v-if="item.acceleration != null ">加速度{{item.acceleration}}m/s²</template>
                  </div>
                  <div class="text" v-if="false">
                    <template v-if="item.abnormal == '0'">1分钟内可能出现碰撞风险</template>
                    <template v-else-if="item.abnormal == Infinity || item.abnormal == NaN"> </template>
                    <template v-else>{{item.abnormal}}分钟后可能出现碰撞风险</template>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="10" style="position: relative;">
            <div class="forecast-area">
              <div class="forecast-bg">
                <div class="label">
                  <span>{{ $t('nextHours') }}</span>
                  <svg
                    width="182"
                    height="33"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <title>background</title>
                      <rect
                        fill="none"
                        id="canvas_background"
                        height="11.16667"
                        width="52.55555"
                        y="-1"
                        x="-1"
                      />
                    </g>

                    <g>
                      <title>Layer 1</title>
                      <path
                        stroke="null"
                        id="svg_1"
                        fill-opacity="0.76"
                        fill="#064996"
                        d="m0,-0.00037c19.24376,0.00012 181.64232,0.00001 181.64232,0.00001c0,0 -18.0591,2.65819 -23.47269,27.23819c-0.67588,3.06909 -3.86189,5.43919 -7.71252,5.43919l-117.5561,0c-3.1326,0 -5.9558,-1.57163 -6.89715,-3.99044c-4.62685,-11.88926 -4.82587,-20.11447 -26.00385,-28.68694l0,-0.00001z"
                      />
                    </g>
                  </svg>
                </div>
                <img class="img" src="/images/img_bg.png" alt="" />
              </div>
              <div class="forecast-detail">
                <div class="data">
                  <div class="tide">
                    <span class="label">{{ $t('position') }}：C{{ pid }}</span>
                    <span class="text"></span>
                  </div>
                  <div class="point">
                    <span class="label">{{ $t('maxVelocity') }}：{{ Number(speed).toFixed(2) }}m/s</span>
                    <span class="text">{{ $t('time') }}：{{ speed_cdate }}</span>
                  </div>
                  <div class="point">
                    <span class="label">{{ $t('highestWaterLevels') }}：{{Number( max_waterlevel).toFixed(2) }}m</span>
                    <span class="text"
                      >{{ $t('time') }}：{{ max_waterlevel_cdate }}</span
                    >
                  </div>
                  <div class="point">
                    <span class="label">{{ $t('lowestWaterLevels') }}：{{ Number(min_waterlevel).toFixed(2) }}m</span>
                    <span class="text"
                      >{{ $t('time') }}：{{ min_waterlevel_cdate }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="2">
            <div style="min-height: 20px"></div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="login-area">
      <div class="user">
        {{ $t('user.name') }}：{{ account
        }}<i class="el-icon-arrow-down" style="color: #3396de"></i>
      </div>
      <div class="dropdown">
        <div class="dropdown-item" @click="handleGo('/reset')">{{ $t('user.reset') }}</div>
        <div class="dropdown-item" @click="changeLanguage()">中/EN</div>
        <div class="dropdown-horizontal"></div>
        <div class="dropdown-item" @click="exit">{{ $t('user.LogOut') }}</div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import Cookie from 'js-cookie';
export default {
  data() {
    return {
      account: this.$utils.getItem("account"),
      // 可以不用设置这个变量，有窗口参数的接口中，不用传值，开发包会默认使用当前选择窗口
      g_iWndIndex: 0,
      socket: null,
      // szIP: "192.168.0.200",
      // szIP: "192.168.1.64",
      szIP: "10.4.16.178",
      post: "80",
      username: "admin",
      // password: "Admin12345",
      password: "Admin12345@hxcom",
      // ip地址
      szDeviceIdentify: "",
      oSel: [],
      // 设备端口
      deviceport: "",
      // RTSP端口
      rtspport: "",
      timer: null,
      date: " ",
      warnList: [],
      pid: "",
      speed: "",
      speed_cdate: "",
      max_waterlevel: "",
      max_waterlevel_cdate: "",
      min_waterlevel: "",
      min_waterlevel_cdate: "",
      larsers1: "",
      larsers2: "",
      larsers3: "",
      larsers4: "",
      larsers5: "",
      delivery1: false,
      delivery2: false,
      delivery3: false,
      delivery4: false,
      delivery5: false,
    };
  },
  mounted() {
    /** 初始化socket */
    this.initSocket();
    /** Web 插件初始化 */
    this.initWebVideo();
    /** 获取当前时间 */
    this.timer = setInterval(() => {
      this.getTime();
    }, 1000);
    this.getWarnShip();
    this.getDelivery();
    

  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.clickLogout();
  },
  methods: {
    getWarnShip() {
      axios({
        url: this.$C.HTTP_REQUEST_URL + "/api/warnShip/max_speed",
        method: "get",
        headers: {
          Authorization: "Bearer " + this.$utils.getItem("X-Admin-Token"),
        },
      }).then((res) => {
        this.pid = res.data.data.pid;
        this.speed = res.data.data.speed;
        this.speed_cdate = this.getNowDate(res.data.data.speed_cdate);
        this.max_waterlevel = res.data.data.max_waterlevel;
        this.max_waterlevel_cdate = this.getNowDate(res.data.data.max_waterlevel_cdate);
        this.min_waterlevel = res.data.data.min_waterlevel;
        this.min_waterlevel_cdate = this.getNowDate(res.data.data.min_waterlevel_cdate);
      });
    },
    /**
     * 初始化socket
     */
    initSocket() {
      var that = this;
      var socket = io("http://10.205.51.106" + ":2140");
      // var socket = io("http://47.103.101.18" + ":2140");
      var uid = 123;
      socket.on("connect", function () {
        socket.emit("login", uid);
      });
      // 后端推送来消息时
      socket.on("new_msg", function (msg) {
        var data = JSON.parse(msg.replaceAll("&quot;", '"'));
        if(data.image) {
          if(data.larsers_id == 1) that.larsers1 = data.image;
          if(data.larsers_id == 2) that.larsers2 = data.image;
          if(data.larsers_id == 3) that.larsers3 = data.image;
          if(data.larsers_id == 4) that.larsers4 = data.image;
          if(data.larsers_id == 5) that.larsers5 = data.image;
        }
        if(data.dataarry) {
          var warnList = Object.values(data.dataarry);
          if(warnList.length > 0) {
            warnList.forEach((item, index) => {
              item.date = that.getWarnTime();
              item.abnormal = Math.floor(Number(Number(item.mindistence).toFixed(2) / Number(item.speed).toFixed(2)) / 60);
              that.warnList.unshift(item);
              that.warnList.splice(2);
            })
          }
        }
      });
    },

    handleGo(path) {
      this.$router.push({ path });
      var warn = this.$route.fullPath;
      if (warn == "/warn") {
        window.location.reload();
      }
    },

    exit() {
      this.$utils.clearItem();
      this.$router.push({ path: "/login" });
    },

    /**
     * Web 插件初始化
     */
    initWebVideo() {
      var that = this;
      /** 初始化插件参数及插入插件 */
      WebVideoCtrl.I_InitPlugin(1300, 300, {
        //是否支持单窗口双击全屏，默认支持 true:支持 false:不支持
        bWndFull: true,
        // 封装格式，2-PS 格式 11-MP4 格式
        iPackageType: 2,
        // 分屏类型：1- 1*1，2- 2*2，3- 3*3，4- 4*4，默认值为 1，单画面
        iWndowType: 3,
        bNoPlugin: true,
        // 窗口选中事件回调函数，只包含一个字符串参数，里面的值是 XML
        cbSelWnd: function (xmlDoc) {
          // console.log("当前选择的窗口编号：", xmlDoc);
        },
        // 窗口双击回调函数，有两个参数，第一个参数是窗口号，第二个参数是是否全屏（无插件不支持该回调）
        cbDoubleClickWnd: function (iWndIndex, bFullScreen) {
          var szInfo = "当前放大的窗口编号：" + iWndIndex;
          if (!bFullScreen) {
            szInfo = "当前还原的窗口编号：" + iWndIndex;
          }
          that.showCBInfo(szInfo);
        },
        // 插件事件回调函数，有三个参数，第一个参数是事件类型，第二参数是窗口号
        cbEvent: function (iEventType, iParam1, iParam2) {
          // 回放正常结束
          if (2 == iEventType) {
            that.showCBInfo("窗口" + iParam1 + "回放结束！");
          } else if (-1 == iEventType) {
            that.showCBInfo("设备" + iParam1 + "网络错误！");
          } else if (3001 == iEventType) {
            that.clickStopRecord(that.g_szRecordType, iParam1);
          }
        },
        // 远程配置库关闭回调
        cbRemoteConfig: function () {
          that.showCBInfo("关闭远程配置库！");
        },
        // 插件初始化完成回调，必须要定义
        cbInitPluginComplete: function () {
          WebVideoCtrl.I_InsertOBJECTPlugin("divPlugin");
          console.log("初始化完成");
          
          let type = Cookie.get('language') 
          if(type == 'en') {
            const cameraHeads = document.getElementsByClassName("camera-video");
            for (let i = 0; i < cameraHeads.length; i++) {
              cameraHeads[i].textContent = "VSAM" + (i+1);
            }
          } else {
            const cameraHeads = document.getElementsByClassName("camera-video");
            for (let i = 0; i < cameraHeads.length; i++) {
              cameraHeads[i].textContent = "视频监控" + (i+1);
            }
      }
          that.clickLogin();
        },
      });
    },

    /** 登录 */
    clickLogin() {
      var szIP = this.szIP,
        szPort = this.post,
        szUsername = this.username,
        szPassword = this.password,
        that = this;
      if ("" == szIP || "" == szPort) {
        return;
      }
      var szDeviceIdentify = szIP + "_" + szPort;
      var iRet = WebVideoCtrl.I_Login(szIP, 1, szPort, szUsername, szPassword, {
        success: function (xmlDoc) {
          // console.log("登录成功", xmlDoc);
          that.showOPInfo(szDeviceIdentify + "登录成功！");
          setTimeout(function () {
            that.szDeviceIdentify = szDeviceIdentify;
            // 获取通道
            that.getChannelInfo();
            that.getDevicePort();
          }, 10);
        },
        error: function (status, xmlDoc) {
          // console.log("登录失败", status);
          that.showOPInfo(szDeviceIdentify + "登录失败！", status, xmlDoc);
        },
      });
      if (-1 == iRet) {
        // console.log("已登录过！", xmlDoc);
        this.showOPInfo(szDeviceIdentify + "已登录过！");
      }
    },

    /** 退出 */
    clickLogout() {
      var szDeviceIdentify = this.szDeviceIdentify,
      szInfo = "";
      if (null == szDeviceIdentify) {
          return;
      }
      var iRet = WebVideoCtrl.I_Logout(szDeviceIdentify);
    },

    /**
     * 获取通道
     */
    getChannelInfo() {
      var szDeviceIdentify = this.szDeviceIdentify,
        oSel = this.oSel,
        that = this;
      if (null == szDeviceIdentify) {
        return;
      }
      /** 模拟通道 */
      WebVideoCtrl.I_GetAnalogChannelInfo(szDeviceIdentify, {
        async: false,
        success: function (xmlDoc) {
          var oChannels = $(xmlDoc).find("VideoInputChannel");
          $.each(oChannels, function (i) {
            var id = $(this).find("id").eq(0).text(),
              name = $(this).find("name").eq(0).text();
            if ("" == name) {
              name = "Camera " + (i < 9 ? "0" + (i + 1) : i + 1);
            }
            oSel.push({
              id: id,
              name: name,
            });
          });
          that.showOPInfo(szDeviceIdentify + " 获取模拟通道成功！");
        },
        error: function (status, xmlDoc) {
          that.showOPInfo(
            szDeviceIdentify + " 获取模拟通道失败！",
            status,
            xmlDoc
          );
        },
      });
      /** 数字通道 */
      WebVideoCtrl.I_GetDigitalChannelInfo(szDeviceIdentify, {
        async: false,
        success: function (xmlDoc) {
          // console.log("数字通道：", xmlDoc);
          var oChannels = $(xmlDoc).find("InputProxyChannelStatus");
          $.each(oChannels, function (i) {
            var id = $(this).find("id").eq(0).text(),
              name = $(this).find("name").eq(0).text(),
              online = $(this).find("online").eq(0).text();
            if ("false" == online) {
              // 过滤禁用的数字通道
              return true;
            }
            if ("" == name) {
              name = "IPCamera " + (i < 9 ? "0" + (i + 1) : i + 1);
            }
            oSel.push({
              id: id,
              name: name,
            });
          });
          that.showOPInfo(szDeviceIdentify + " 获取数字通道成功！");

          setTimeout(() => {
            that.clickStartRealPlay();
          }, 20);
        },
        error: function (status, xmlDoc) {
          that.showOPInfo(
            szDeviceIdentify + " 获取模拟通道失败！",
            status,
            xmlDoc
          );
        },
      });
      /** 零通道 */
      WebVideoCtrl.I_GetZeroChannelInfo(szDeviceIdentify, {
        async: false,
        success: function (xmlDoc) {
          var oChannels = $(xmlDoc).find("ZeroVideoChannel");
          $.each(oChannels, function (i) {
            var id = $(this).find("id").eq(0).text(),
              name = $(this).find("name").eq(0).text();
            if ("" == name) {
              name = "Zero Channel " + (i < 9 ? "0" + (i + 1) : i + 1);
            }
            if ("true" == $(this).find("enabled").eq(0).text()) {
              // 过滤禁用的零通道
              oSel.push({
                id: id,
                name: name,
              });
            }
          });
          that.showOPInfo(szDeviceIdentify + " 获取零通道成功！");
        },
        error: function (status, xmlDoc) {
          that.showOPInfo(
            szDeviceIdentify + " 获取零通道失败！",
            status,
            xmlDoc
          );
        },
      });
    },

    /**
     * 获取端口
     */
    getDevicePort() {
      var szDeviceIdentify = this.szDeviceIdentify;
      if (null == szDeviceIdentify) {
        return;
      }
      var oPort = WebVideoCtrl.I_GetDevicePort(szDeviceIdentify);
      if (oPort != null) {
        this.deviceport = oPort.iDevicePort;
        this.rtspport = oPort.iRtspPort;
        this.showOPInfo(szDeviceIdentify + " 获取端口成功！");
      } else {
        this.showOPInfo(szDeviceIdentify + " 获取端口失败！");
      }
    },

    /** 开始预览 */
    clickStartRealPlay(iStreamType) {
      /** g_iWndIndex 播放窗口，如果不传，则默认使用当前选择窗口播放（默认选中窗口 0） */
      var oWndInfo = WebVideoCtrl.I_GetWindowStatus(this.g_iWndIndex),
        /** 设备标识（IP_Port） */
        szDeviceIdentify = this.szDeviceIdentify,
        iRtspPort = parseInt(this.rtspport, 10),
        /** 通道id */
        iChannelID = parseInt(this.oSel[this.g_iWndIndex].id, 10),
        iStreamType = 2,
        that = this,
        szInfo;
      var startRealPlay = function () {
        /** szDeviceIdentify  */
        WebVideoCtrl.I_StartRealPlay(szDeviceIdentify, {
          iWndIndex: that.g_iWndIndex,
          iRtspPort: iRtspPort,
          iStreamType: iStreamType,
          iChannelID: iChannelID,
          bZeroChannel: false,
          success: function () {
            if (that.g_iWndIndex < 4) {
              that.g_iWndIndex = that.g_iWndIndex + 1;
              setTimeout(() => {
                that.clickStartRealPlay();
              }, 50);
            }
          },
          error: function (status, xmlDoc) {
            console.log(status);
          },
        });
      };
      if (oWndInfo != null) {
        // 已经在播放了，先停止
        // console.log("已经在播放了，先停止")
        WebVideoCtrl.I_Stop({
          success: function () {
            startRealPlay();
          },
        });
      } else {
        // console.log("直接请求")
        startRealPlay();
      }
    },

    /** 全屏 */
    clickFullScreen() {
      WebVideoCtrl.I_FullScreen(true);
    },

    /**
     * 显示操作信息
     */
    showOPInfo(szInfo, status, xmlDoc) {
      var szTip =
        "<div>" +
        this.dateFormat(new Date(), "yyyy-MM-dd hh:mm:ss") +
        " " +
        szInfo;
      if (typeof status != "undefined" && status != 200) {
      }
    },

    /**
     * 显示回调信息
     */
    showCBInfo(szInfo) {
      szInfo =
        "<div>" +
        dateFormat(new Date(), "yyyy-MM-dd hh:mm:ss") +
        " " +
        szInfo +
        "</div>";
    },

    /** 格式化时间 */
    dateFormat(oDate, fmt) {
      var o = {
        "M+": oDate.getMonth() + 1, //月份
        "d+": oDate.getDate(), //日
        "h+": oDate.getHours(), //小时
        "m+": oDate.getMinutes(), //分
        "s+": oDate.getSeconds(), //秒
        "q+": Math.floor((oDate.getMonth() + 3) / 3), //季度
        S: oDate.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (oDate.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    },

    /**
     * 停止录像
     */
    clickStopRecord(szType, iWndIndex) {
      let that = this;
      if ("undefined" == typeof iWndIndex) {
        iWndIndex = this.g_iWndIndex;
      }
      var oWndInfo = WebVideoCtrl.I_GetWindowStatus(iWndIndex),
        szInfo = "";
      if (oWndInfo != null) {
        WebVideoCtrl.I_StopRecord({
          success: function () {
            if ("realplay" == szType) {
              szInfo = "停止录像成功！";
            } else if ("playback" === szType) {
              szInfo = "停止剪辑成功！";
            }
            that.showOPInfo();
          },
        });
      }
    },

    /**
     * 获取当前告警时间
     */
    getWarnTime() {
      var mydate = new Date();
      var year = mydate.getFullYear();
      var month = mydate.getMonth() +1;
      var day = mydate.getDate();
      var hour = mydate.getHours();
      var minutes = mydate.getMinutes();
      var seconds = mydate.getSeconds();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      var time = `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
      return time;
    },
    /**
     * 获取当前时间
     */
    getTime() {
      var mydate = new Date();
      var year = mydate.getFullYear();
      var month = mydate.getMonth() + 1;
      var day = mydate.getDate();
      var hour = mydate.getHours();
      var minutes = mydate.getMinutes();
      var now = "";
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (hour <= 12) {
        now = "上午";
      } else {
        now = "下午";
      }
      var seconds = mydate.getSeconds();
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      var weekday = mydate.getDay();
      var arr = new Array(
        "星期天",
        "星期⼀",
        "星期⼆",
        "星期三",
        "星期四",
        "星期五",
        "星期六"
      );
      var time = `${year}年${month}月${day}日 ${arr[weekday]} ${hour}:${minutes}:${seconds}`;
      this.date = time;
    },

    /** 获取当前时间 */
    getNowDate(date) {
      var nowDate = new Date(date);
      var nowYear = nowDate.getFullYear();
      var nowMonth = nowDate.getMonth();
      var nowDay = nowDate.getDate();
      var nowHour = nowDate.getHours();
      var nowMinutes = nowDate.getMinutes();
      nowMonth = nowMonth + 1;
      nowMonth = nowMonth.toString().padStart(2, "0");
      nowDay = nowDay.toString().padStart(2, "0");
      nowHour = nowHour.toString().padStart(2, "0");
      nowMinutes = nowMinutes.toString().padStart(2, "0");
      nowMinutes = Math.floor(nowMinutes / 10)
        .toString()
        .padEnd(2, "0");
      return `${nowYear}-${nowMonth}-${nowDay} ${nowHour}:${nowMinutes}`;
    },

    getDelivery() {
      axios({
        url: this.$C.HTTP_REQUEST_URL + "/api/warnPolice/list",
        method: "get",
        headers: {
          Authorization: "Bearer " + this.$utils.getItem("X-Admin-Token"),
        },
      }).then((res) => {
        if (res.data.data[0].status == 1) {
          this.delivery1 = true;
        } else {
          this.delivery1 = false;
        }
        if (res.data.data[1].status == 1) {
          this.delivery2 = true;
        } else {
          this.delivery2 = false;
        }
        if (res.data.data[2].status == 1) {
          this.delivery3 = true;
        } else {
          this.delivery3 = false;
        }
        if (res.data.data[3].status == 1) {
          this.delivery4 = true;
        } else {
          this.delivery4 = false;
        }
        if (res.data.data[4].status == 1) {
          this.delivery5 = true;
        } else {
          this.delivery5 = false;
        }
      });
    },
    
    changeLanguage() {
      let language = Cookie.get("language"),
      type;
      if (language == 'en') type = 'zh';
      else if (language == 'zh') type = 'en';
      else type = 'en';
      Cookie.set('language', type) 
      this.$i18n.locale = type 
      if(type == 'en') {
        const cameraHeads = document.getElementsByClassName("camera-video");
        for (let i = 0; i < cameraHeads.length; i++) {
          cameraHeads[i].textContent = "VSAM" + (i+1);
        }
      } else {
        const cameraHeads = document.getElementsByClassName("camera-video");
        for (let i = 0; i < cameraHeads.length; i++) {
          cameraHeads[i].textContent = "视频监控" + (i+1);
        }
      }
      // this.$message.success('切换多语言成功')
    }
  },
};
</script>

<style lang="scss" scoped>
.bg-img {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
  margin: 0 10px;
}

.bg-purple {
  background: #d3dce6;
}

.map-layout {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: aquamarine;
}

.camera-col {
  // max-width: 347px;
  // margin: 0 8px;
}

.header_l,
.header_r {
  z-index: 20000;
  position: fixed;
  top: 30px;
  left: 30px;
  height: 52px;
  background-color: #fff;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    padding-left: 18px;

    img {
      width: 226px;
    }
  }

  .tit {
    font-size: 24px;
    margin: 0 10px;
    font-family: cursive;
    font-weight: bold;
  }

  .nav {
    display: flex;
    align-items: center;
    justify-content: center;

    .item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 52px;
      border-radius: 30px;
      font-size: 16px;
      color: #2a3537;
      cursor: pointer;
      // width: 150px;
      min-width: 150px;
      padding: 0 6px;
      box-sizing: border-box;

      &.active {
        background-color: #3396de;
        color: #fff;
      }
    }
  }
}
.header_r {
  left: 965px;
  // left: 900px;
  .tit {
    margin-left: 16px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  padding-top: 110px;
  position: relative;
  z-index: 2000;
}

.bottom {
  position: relative;
  z-index: 2000;
  margin-top: 50px;

  .warn-area {
    width: 95%;
    height: 214px;
    backdrop-filter: blur(11px);
    padding: 0 14px;
    box-sizing: border-box;

    .warn-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;

      .warn-head-title {
        font-size: 18px;
        color: #2a3537;
        font-weight: bold;
      }

      .warn-head-more {
        width: 46px;
        height: 24px;
        background: #6f94bc;
        border-radius: 17px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          width: 6px;
          height: 6px;
          border-radius: 3px;
          margin: 0 2px;
          background-color: #fff;
        }
      }
    }

    .warn-info {
      border-top: 2px solid rgba(113, 216, 242, 0.4);
      border-bottom: 2px solid rgba(113, 216, 242, 0.4);
      padding-top: 18px;
      padding-bottom: 20px;
      height: 128px;

      .warn-info-item {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 10px;
        box-sizing: border-box;
        margin-bottom: 4px;
        background: rgba(151, 189, 231, 0.6);
        backdrop-filter: blur(11px);
        border-left: 4px solid #3396de;

        &:first-child {
          border-left: 4px solid #ee191f;
        }

        .img {
          width: 120px;
          height: 60px;
          margin-right: 6px;
          background-image: #000;

          img {
            width: 120px;
            height: 60px;
          }
        }

        .text {
          font-size: 12px;
          color: #fff;
          opacity: 0.8;
          text-align: center;
        }
      }
    }
  }

  .forecast-area {
    width: 95%;
    height: 215px;
    float: right;
    backdrop-filter: blur(11px);
    overflow: hidden;

    .forecast-bg {
      position: absolute;
      width: 230px;
      height: 215px;
      border-radius: 17px;
      border: 2px solid #fff;
      box-sizing: border-box;
      margin-right: 15px;
      left: 0px;
      z-index: 1;

      .label {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        color: #fff;
        font-size: 18px;
        text-align: center;
        line-height: 32px;

        span {
          position: relative;
          z-index: 1;
        }

        svg {
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
        }
      }

      .img {
        width: 100%;
        height: 100%;
        border-radius: 17px;
      }
    }

    .forecast-detail {
      position: relative;
      background-color: rgba(175, 212, 227, 0.14);
      border-top: 2px solid rgba(255, 255, 255, 0.3);
      border-bottom: 2px solid rgba(255, 255, 255, 0.3);
      padding-left: 220px;
      margin-left: 13px;
      overflow: hidden;
      height: 211px;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      .label {
        font-size: 16px;
        display: inline-block;
        // width: 160px;
        min-width: 150px;
        margin-right: 10px;
        color: #fff;
        opacity: 0.8;
      }

      .text {
        font-size: 16px;
        display: inline-block;
        color: #fff;
        opacity: 0.8;
      }

      .data {
        padding: 20px 0 0 0;

        .tide {
          margin-bottom: 24px;
        }
      }

      .point {
        margin-bottom: 24px;
      }

      .date {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 50px;
        padding-right: 30px;
        line-height: 50px;

        .svg {
          position: absolute;
          bottom: 0;
          top: 0;
          right: 0;
          z-index: 0;
          display: flex;
          align-items: center;
          opacity: 0.7;
        }

        .current-time {
          font-size: 20px;
          position: relative;
          z-index: 111;
          color: #fff;
          opacity: 0.8;
        }

        .bold {
          font-weight: bold;
        }
      }

      .line {
        position: absolute;
        bottom: -2px;
        right: 0;
      }
    }
  }
}

.bg-purple-dark {
  background: #99a9bf;
}

.tag-content {
  z-index: 2000;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 14px;
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  .tag-logo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .tag-title {
    font-size: 20px;
    color: #fff;
  }
}

.login-area {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 2000;
  display: flex;
  flex-direction: column;

  &:hover {
    .dropdown {
      display: inline-flex;
    }
  }

  .dropdown {
    display: none;
    justify-content: flex-start;
    flex-direction: column;
    margin: 10px auto 0;
    border-radius: 6px;
    padding: 6px;
    box-shadow: 0 3px 14px 2px rgba(0, 0, 0, .05), 0 8px 10px 1px rgba(0, 0, 0, 6%), 0 5px 5px -3px rgba(0, 0, 0, 10%);
    background-color: #fff;
    font-size: 14px;
    color: #2a3537;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -20px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom: 10px solid #fff;
    }

    .dropdown-item {
      padding: 4px 10px;
      text-align: center;
      cursor: pointer;
      &:hover {
        background-color: #f3f3f3;
        color: #3396de;
      }
    }
    .dropdown-horizontal {
      height: 0.5px;
      margin: 2px 0;
      background-color: #ccc;
    }
  }

  .user {
    background: #fff;
    padding: 10px 16px;
    border-radius: 22px;
    color: #2a3537;
  }
}
.set-delivery {
  position: absolute;
  top: 10px;
  right: 5px;
  display: inline-flex;
  backdrop-filter: blur(11px);
  width: 170px;
  font-size: 14px;
  height: 30px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  color: #f5f5f5;
  border: 1px solid #f5f5f5;

  .el-switch {
    margin: 0 5px;
  }
}
</style>

<style lang="scss">
.camera-area {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  .camera-col {
    max-width: 347px;
    margin: 0 8px;
    display: flex;
    flex-direction: column;
    height: 200px;
    background-color: rgba(111, 148, 188, 0.5);
    border-left: 2px solid #16d0ff;
    border-right: 2px solid #16d0ff;
    box-sizing: border-box;
    backdrop-filter: blur(11px);

    &:fullscreen {
      border: none;

      .camera-head {
        display: none;
      }

      .camera-box {
        border: none;
        height: 100%;
      }

      .camera-bar {
        display: none;
      }

      canvas {
        border: none !important;
      }

      .camera-con {
        margin: 0;
      }
    }

    .camera-head {
      display: flex;
      align-items: center;
      padding-left: 30px;
      width: 100%;
      height: 50px;
      font-size: 20px;
      color: #2a3537;
      border-top: 2px solid rgba(113, 216, 242, 0.3);
      border-bottom: 2px solid rgba(113, 216, 242, 0.3);
      box-sizing: border-box;
      font-weight: bold;
    }

    .camera-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 147px;
      border-bottom: 2px solid rgba(113, 216, 242, 0.3);
    }

    .camera-con {
      flex: 1;
      position: relative;
      // width: 287px;
      margin: 10px 0;
      box-sizing: border-box;
      border-radius: 17px;
      display: flex;
      align-items: center;
      background-color: #000;

      img {
        width: 100%;
        height: 100%;
        border-radius: 17px;
      }
    }

    .camera-bar {
      width: 28px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .camera-rhomb {
        width: 8px;
        height: 14px;
        transform: rotateZ(90deg) skew(330deg, 0deg);
        background: #e86402;
        border-radius: 2px;
        margin-bottom: -2px;
      }
    }
  }
}

.el-row {
  width: 100% !important;
}

.el-col-2 {
  width: 8.33333% !important;
}

.el-col-4 {
  width: 16.66667% !important;
}

canvas {
  border: none !important;
}
</style>
