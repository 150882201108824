
const httpApi = "http://cws.huaxingocean.com";
// let httpApi = "http://10.205.51.106:8088";
export default {
  // 请求地址
  baseURL:  "",
  HTTP_REQUEST_URL: httpApi,
  // 回话密钥名称 请勿修改此配置
	TOKENNAME: 'X-Token',
};
