import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookie from 'js-cookie'
import elementZh from 'element-ui/lib/locale/lang/zh-CN'
import elementEN from 'element-ui/lib/locale/lang/en'
 
import zh from './zh'
import en from './en'
 
Vue.use(VueI18n)
 
const i18n = new VueI18n({
  locale: Cookie.get('language') || 'zh',  //切换locale的值就可以切换语言了，当locale为 'zh' 时，t(key)和$t(key)函数就会根据传入的key去 message中 zh 指定的语言包中找到key对应的值，key值支持带  . 号的字符串
  messages: {
    en: {
      ...elementEN,
      ...en
    },
    zh: {
      ...elementZh,
      ...zh
    }
  }
})

export default i18n
