import map from "./map";

const getItem = (key) => {
    return sessionStorage.getItem(key);
}

const setItem = (key, value) => {
    return sessionStorage.setItem(key, value);
}

const clearItem = () => {
    return sessionStorage.clear();
}

/**
 * 度转度°分′秒″
 * @param {*} val
 */
const ToDegrees = (val) => {
    if (typeof val == "undefined" || val == "") {
        return "";
    }
    var i = val.indexOf(".");
    var strDu = i < 0 ? val : val.substring(0, i); //获取度
    var strFen = 0;
    var strMiao = 0;
    if (i > 0) {
        var strFen = "0" + val.substring(i);
        strFen = strFen * 60 + "";
        i = strFen.indexOf(".");
        if (i > 0) {
            strMiao = "0" + strFen.substring(i);
            strFen = strFen.substring(0, i); //获取分
            strMiao = strMiao * 60 + "";
            i = strMiao.indexOf(".");
            strMiao = strMiao.substring(0, i + 4); //取到小数点后面三位
            strMiao = parseFloat(strMiao).toFixed(2); //精确小数点后面两位
            strMiao = (parseFloat(strMiao / 60).toFixed(2)) * 100;
            if(strMiao < 10) {
                strMiao = strMiao + '0'  
            }
        }
    }
    return strDu + "°" + strFen + "." + Math.round(strMiao) + "′";
};

const windDirection = (degrees) => {
const directArr = [
    "北",
    "北东北",
    "东北",
    "东东北",
    "东",
    "东东南",
    "东南",
    "南东南",
    "南",
    "南西南",
    "西南",
    "西西南",
    "西",
    "西西北",
    "西北",
    "北西北",
];
let index = 0;
if (348.75 <= degrees && degrees <= 360) {
    index = 0;
} else if (0 <= degrees && degrees <= 11.25) {
    index = 0;
} else if (11.25 < degrees && degrees <= 33.75) {
    index = 1;
} else if (33.75 < degrees && degrees <= 56.25) {
    index = 2;
} else if (56.25 < degrees && degrees <= 78.75) {
    index = 3;
} else if (78.75 < degrees && degrees <= 101.25) {
    index = 4;
} else if (101.25 < degrees && degrees <= 123.75) {
    index = 5;
} else if (123.75 < degrees && degrees <= 146.25) {
    index = 6;
} else if (146.25 < degrees && degrees <= 168.75) {
    index = 7;
} else if (168.75 < degrees && degrees <= 191.25) {
    index = 8;
} else if (191.25 < degrees && degrees <= 213.75) {
    index = 9;
} else if (213.75 < degrees && degrees <= 236.25) {
    index = 10;
} else if (236.25 < degrees && degrees <= 258.75) {
    index = 11;
} else if (258.75 < degrees && degrees <= 281.25) {
    index = 12;
} else if (281.25 < degrees && degrees <= 303.75) {
    index = 13;
} else if (303.75 < degrees && degrees <= 326.25) {
    index = 14;
} else if (326.25 < degrees && degrees < 348.75) {
    index = 15;
}
return directArr[index];
};

export default {
    map,
    getItem,
    setItem,
    clearItem,
    ToDegrees: ToDegrees,
    windDirection: windDirection
}