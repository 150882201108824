<template>
  <div>
    <map-con style="filter: blur(3px)" />
    <div class="header_l">
      <div class="logo">
        <img src="/images/logo.png" alt="logo">
      </div>
      <div class="tit">黄岛油港区</div>
      <div class="nav">
        <div class="item" @click="handleGo('/forecast')">潮流预报</div>
        <div class="item active" @click="handleGo('/antiCollision')">监控预警</div>
        <div class="item" @click="handleGo('/d90')">90泊位预报</div>
      </div>
    </div>
    <div class="header_r">
      <div class="tit">董家口港区</div>
      <div class="nav">
        <div class="item" @click="handleGo('/d12')">D12泊位预报</div>
      </div>
    </div>

    <div class="container">
      <div class="warn-area">
        <div class="warn-head">
          <div class="warn-head-title">告警信息</div>
        </div>
        <el-form size="small" label-width="120px">
          <el-form-item label="雷达">
            <el-select v-model="lidvalue" placeholder="请选择雷达" multiple>
              <el-option
                v-for="item in lidList"
                :key="item.lid"
                :label="item.name"
                :value="item.lid"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="时间" style="display: inline-block; margin-right: 20px">
            <el-date-picker
            v-model="endDate"
              type="datetimerange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              @change="changeDate"
            />
          </el-form-item>
          <el-button
            style="margin-left: 30px"
            size="small"
            type="primary"
            @click="search"
            >搜索</el-button
          >
        </el-form>
        <div class="warn-list" v-if="warnList.length > 0">
          <div class="line l t"></div>
          <div class="line r t"></div>
          <div class="line "></div>
          <div class="line"></div>
          <div class="warn-list-item" v-for="(item, index) in warnList" :key="index">
            <div class="img">
              <el-image v-if="item.img_url != null && item.img_url != ''" :src="item.img_url" :preview-src-list="[item.img_url]" >
                <img slot="error" src="/images/testimg3.png">
              </el-image>
              <img v-else src="/images/testimg3.png">
            </div>
            <div class="text">雷达{{item.lid}}</div>
            <div class="text">{{item.cdate}}</div>
            <div class="text">距离岸边{{Number(item.shoreside).toFixed(1)}}米</div>
            <div class="text">船速为{{item.ship_speed}}m/s</div>
            <div class="text">
              <template v-if="item.acceleration == null ">加速度为0m/s²</template>
              <template v-if="item.acceleration != null ">加速度{{item.acceleration}}m/s²</template>
            </div>
            <el-button
              style="margin-left: 30px"
              size="small"
              type="primary"
              @click="changeItem(item)"
              >查看录像</el-button
            >
          </div>
        </div>
        <div class="warn-pagination" v-if="warnList.length > 0">
          <el-pagination
            background
            layout="prev, pager, next"
            prev-text=" "
            next-text=" "
            :page-size="PageSize"
            :total="total"
            :current-page="page"
            @current-change="handleCurrentChange" 
          />
        </div>
      </div>
    </div>

    <div class="login-area">
      <div class="user">用户：{{account}}<i class="el-icon-arrow-down" style="color: #3396DE"></i></div>
      <div class="dropdown">
        <div class="dropdown-item" @click="handleGo('/reset')">修改密码</div>
        <div class="dropdown-horizontal"></div>
        <div class="dropdown-item" @click="exit">退出登录</div>
      </div>
    </div>

    <el-dialog
      title="视频回放"
      width="820px"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      :before-close="handleClose">
      <div id="divPlugin" class="plugin"></div>
      <div class="dialog-footer">
        <el-button type="primary" @click="clickRecordSearch()">查看录像</el-button>
        <el-button type="primary" @click="clickFullScreen()">全屏</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import MapCon from "@/components/map";
import axios from "axios";
export default {
  name: "Warn",
  components: {
    MapCon
  },
  data() {
    return {
      account: this.$utils.getItem("account"),
      // 默认每页显示的条数（可修改）
      PageSize:5,
      total: 0,
      lid: "",
      start_cdate: "",
      end_cdate: "",
      warnList: [],
      page: 1,
      endDate: [],
      lidList: [
        { lid: 1, name: "雷达1" },
        { lid: 2, name: "雷达2" },
        { lid: 3, name: "雷达3" },
        { lid: 4, name: "雷达4" },
        { lid: 5, name: "雷达5" },
      ],
      lidvalue: [],
      dialogVisible: false,
      // szIP: "192.168.0.200",
      // szIP: "192.168.1.64",
      szIP: "10.4.16.178",
      post: "80",
      username: "admin",
      // password: "Admin12345",
      password: "Admin12345@hxcom",
      // ip地址
      szDeviceIdentify: "",
      oSel: [],
      iStreamType: 1, // 1 主码流 2 子码流
      lid: "",
      szStartTime: "",
      szEndTime: "",
      g_iSearchTimes: 0,
      // 设备端口
      deviceport: "",
      // RTSP端口
      rtspport: "",
    };
  },
  mounted() {
    this.getWarnInfo("", "");
    // this.getWarnInfo("2023-01-20 23:53:10", "2023-01-20 23:54:00");
  },
  beforeDestroy() {
    this.clickLogout();
  },
  methods: {
    search() {
      this.page = 1;
      this.getWarnInfo(this.start_cdate, this.end_cdate);
    },
    getWarnInfo(start_cdate, end_cdate) {
      axios({
        url: this.$C.HTTP_REQUEST_URL + "/api/warnInfoEarly/list",
        method: "get",
        headers: {
          'Authorization': 'Bearer ' + this.$utils.getItem("X-Admin-Token"),
        },
        params: {
          page: this.page,
          limit: this.PageSize,
          lids: this.lidvalue,
          start_cdate: start_cdate,
          end_cdate: end_cdate
        }
      }).then(res => {
        this.total = res.data.data.count;
        this.warnList = res.data.data.list;
      })
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getWarnInfo(this.start_cdate, this.end_cdate);
    },
    changeDate(e) {
      if (!e == !1) {
        this.start_cdate = e[0];
        this.end_cdate = e[1];
      } else {
        this.start_cdate = "";
        this.end_cdate = "";
      }
    },
    handleGo(path) {
      this.$router.push({ path });
      var antiCollision = this.$route.fullPath;
      if (antiCollision == '/antiCollision') {
        window.location.reload();
      }
    },
    exit() {
      this.$utils.clearItem();
      this.$router.push({ path: '/login' });
    },
    // Web 插件初始化
    initWebVideo() {
      var that = this;
      /** 初始化插件参数及插入插件 */
      WebVideoCtrl.I_InitPlugin2(750, 450, {
        //是否支持单窗口双击全屏，默认支持 true:支持 false:不支持
        bWndFull: true,
        // 封装格式，2-PS 格式 11-MP4 格式
        iPackageType: 2,
        // 分屏类型：1- 1*1，2- 2*2，3- 3*3，4- 4*4，默认值为 1，单画面
        iWndowType: 1,
        bNoPlugin: true,
        // 插件事件回调函数，有三个参数，第一个参数是事件类型，第二参数是窗口号
        cbEvent: function (iEventType, iParam1, iParam2) {
          // 回放正常结束
        },
        // 远程配置库关闭回调
        cbRemoteConfig: function () {
        },
        // 插件初始化完成回调，必须要定义
        cbInitPluginComplete: function () {
          WebVideoCtrl.I_InsertOBJECTPlugin("divPlugin");
          that.clickLogin();
        },
      })
    },
    
    /** 登录 */
    clickLogin() {
      var szIP = this.szIP,
        szPort = this.post,
        szUsername = this.username,
        szPassword = this.password,
        that = this;
      if ("" == szIP || "" == szPort) {
        return;
      }
      var szDeviceIdentify = szIP + "_" + szPort;
      var iRet = WebVideoCtrl.I_Login(szIP, 1, szPort, szUsername, szPassword, {
        success: function (xmlDoc) {
          setTimeout(function() {
            that.szDeviceIdentify = szDeviceIdentify;
            that.getChannelInfo();
            that.getDevicePort();
          }, 10)
        },
        error: function (status, xmlDoc) {
          // console.log("登录失败", status);
        }
      });
      if (-1 === iRet) {
        // console.log("已登录过！");
      }
    },

    /** 退出 */
    clickLogout() {
      var szDeviceIdentify = this.szDeviceIdentify,
      szInfo = "";
      if (null == szDeviceIdentify) {
          return;
      }
      var iRet = WebVideoCtrl.I_Logout(szDeviceIdentify);
      // console.log("iRet:", iRet);
    },

    /** 获取通道 */
    getChannelInfo() {
      var szDeviceIdentify = this.szDeviceIdentify,
        oSel = this.oSel,
        that = this;
      if (null == szDeviceIdentify) {
        return;
      }
      /** 数字通道 */
      WebVideoCtrl.I_GetDigitalChannelInfo(szDeviceIdentify, {
        async: false,
        success: function (xmlDoc) {
          // console.log("数字通道：", xmlDoc);
          var oChannels = $(xmlDoc).find("InputProxyChannelStatus");
          $.each(oChannels, function (i) {
            var id = $(this).find("id").eq(0).text(),
              name = $(this).find("name").eq(0).text(),
              online = $(this).find("online").eq(0).text();
            if ("false" == online) {
              // 过滤禁用的数字通道
              return true;
            }
            if ("" == name) {
              name = "IPCamera " + (i < 9 ? "0" + (i + 1) : i + 1);
            }
            oSel.push({
              id: id,
              name: name,
            });
          });
          // console.log("获取数字通道成功！");
        },
        error: function (status, xmlDoc) {
          // console.log("获取数字通道失败！", status);
        }
      })
    },

    /** 获取端口 */
    getDevicePort() {
      var szDeviceIdentify = this.szDeviceIdentify;
      if (null == szDeviceIdentify) {
        return;
      }
      var oPort = WebVideoCtrl.I_GetDevicePort(szDeviceIdentify);
      if (oPort != null) {
        this.deviceport = oPort.iDevicePort;
        this.rtspport = oPort.iRtspPort;
      }
    },

    /** 搜索录像 */
    clickRecordSearch() {
      var szDeviceIdentify = this.szDeviceIdentify,
        iChannelID = this.lid,
        iStreamType = 1,  // 1 主码流
        szStartTime = this.szStartTime,
        szEndTime = this.szEndTime,
        g_iSearchTimes = this.g_iSearchTimes,
        that = this;

      if (null == szDeviceIdentify) {
        return;
      }
      WebVideoCtrl.I_RecordSearch(szDeviceIdentify, iChannelID, szStartTime, szEndTime, {
        iStreamType: iStreamType,
        g_iSearchTimes: g_iSearchTimes * 40,
        success: function(xmlDoc) {
          if ("MORE" === $(xmlDoc).find("responseStatusStrg").eq(0).text()){
            that.clickStartPlayback();
          } else if ("OK" === $(xmlDoc).find("responseStatusStrg").eq(0).text()) {
            var iLength = $(xmlDoc).find("searchMatchItem").length;
            for (var i = 0; i < iLength; i++) {
              var szPlaybackURI = $(xmlDoc).find("playbackURI").eq(i).text();
              if (szPlaybackURI.indexOf("name=") < 0) {
                break;
              }
              var szStartTime = $(xmlDoc).find("startTime").eq(i).text();
              var szEndTime = $(xmlDoc).find("endTime").eq(i).text();
              var szFileName = szPlaybackURI.substring(szPlaybackURI.indexOf("name=") + 5, szPlaybackURI.indexOf("&size="));
              // console.log("开始时间：" + szStartTime, + " ,结束时间：" + szEndTime + " , 文件名：" + szFileName);
            }
            // console.log("搜索录像文件成功！");
            that.clickStartPlayback();
          } else if ("NO MATCHES" === $(xmlDoc).find("responseStatusStrg").eq(0).text()) {
            // console.log(szDeviceIdentify + "没有录像文件！");
            that.$message({
              message: '没有录像文件！',
              type: "error",
              duration: 3000,
              offset: 110
            })
          }
        },
        error: function(status, xmlDoc) {
          that.$message({
            message: '搜索录像文件失败！',
            type: "error",
            duration: 3000,
            offset: 110
          })
          // console.log(szDeviceIdentify + "搜索录像文件失败！", status, xmlDoc);
        }
      })
    },

    /** 开始回放 */
    clickStartPlayback() {
      var oWndInfo = WebVideoCtrl.I_GetWindowStatus(this.lid),
        szDeviceIdentify = this.szDeviceIdentify,
        iRtspPort = this.rtspport,
        // iStreamType = this.iStreamType,
        iStreamType = 1,
        bZeroChannel = false, // true 零通道 false 非零通道
        iChannelID = this.lid,
        szStartTime = this.szStartTime, // 开始时间
        szEndTime = this.szEndTime, // 结束时间
        szInfo = "",
        bChecked = false; // 是否启用转码码流
      if (null == szDeviceIdentify) {
        return;
      }

      var startPlayback = function () {
        WebVideoCtrl.I_StartPlayback(szDeviceIdentify, {
          iRtspPort: iRtspPort,
          iStreamType: iStreamType,
          iChannelID: iChannelID,
          szStartTime: szStartTime,
          szEndTime: szEndTime,
          success: function() {
            // console.log("开始回放成功！");
          },
          error: function(status, xmlDoc) {
            // console.log("开始回放失败！", status, xmlDoc);
            // if (403 === status) {
            //   console.log("设备不支持Websocket取流！");
            // } else {
            //   console.log("开始回放失败！");
            // }
          }
        })
      }

      if (oWndInfo != null) {
        WebVideoCtrl.I_Stop({
          success: function() {
            startPlayback();
          }
        })
      } else {
        startPlayback();
      }
    },

    /** 停止回放 */
    clickStopPlayback() {
      var oWndInfo = WebVideoCtrl.I_GetWindowStatus(this.lid);
      if (oWndInfo != null) {
        WebVideoCtrl.I_Stop({
          success: function () {
            // console.log("停止回放成功！");
          },
          error: function () {
            // console.log("停止回放失败！");
          }
        });
      }
    },

    /** 全屏 */
    clickFullScreen() {
      WebVideoCtrl.I_FullScreen(true);
    },

    changeItem(item) {
      this.lid = item.lid;
      this.szStartTime = this.getStartTime(item.create_time);
      this.szEndTime = this.getEndTime(item.create_time);
      this.dialogVisible = true;
      /** Web 插件初始化 */
      this.initWebVideo();
    },
    getStartTime(time) {
      var now = new Date(time).getTime();
      var szStartTime = new Date(time);
      szStartTime.setTime(now - 30000);
      var year = szStartTime.getFullYear();
      var month = szStartTime.getMonth() +1;
      var day = szStartTime.getDate();
      var hours = szStartTime.getHours();
      var minutes = szStartTime.getMinutes();
      var seconds = szStartTime.getSeconds();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (hours >= 0 && hours <= 9) {
        hours = "0" + hours;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      var time = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return time;
    },
    getEndTime(time) {
      var now = new Date(time).getTime();
      var szEndTime = new Date(time);
      szEndTime.setTime(now + 30000);
      var year = szEndTime.getFullYear();
      var month = szEndTime.getMonth() +1;
      var day = szEndTime.getDate();
      var hours = szEndTime.getHours();
      var minutes = szEndTime.getMinutes();
      var seconds = szEndTime.getSeconds();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (hours >= 0 && hours <= 9) {
        hours = "0" + hours;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      var time = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return time;
    },
    handleClose(done) {
      var oWndInfo = WebVideoCtrl.I_GetWindowStatus(0);
      if (oWndInfo != null) {
        WebVideoCtrl.I_Stop({
          success: function () {
            done();
          },
          error: function () {
            done();
          }
        });
      }else {
        done();
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.plugin {
  width: 750px;
  height: 450px;
  margin: 0 auto 20px;
}
.dialog-footer {
  width: 750px;
  margin: 0 auto;
}
.container {
  display: flex;
  flex-direction: column;
  padding-top: 110px;
  position: relative;
  z-index: 2000;
}

.header_l,
.header_r {
  z-index: 2010;
  position: fixed;
  top: 30px;
  left: 30px;
  height: 52px;
  background-color: #fff;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    padding-left: 18px;
    img {
      width: 226px;
    }
  }
  .tit {
    font-size: 24px;
    margin: 0 10px;
    font-family: cursive;
    font-weight: bold;
  }
  .nav {
    display: flex;
    align-items: center;
    justify-content: center;
    .item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 52px;
      border-radius: 30px;
      font-size: 16px;
      color: #2A3537;
      cursor: pointer;
      &.active {
        background-color: #3396DE;
        color: #fff;
      }
    }
  }
}
.header_l {
  left: 30px;
}
.header_r {
  left: 900px;
  .tit {
    margin-left: 16px;
  }
}
.el-main {
  background-color: #E9EEF3;
}
.el-footer {
  height: 40px;
}
.tag-content {
  z-index: 2000;
  position: relative;
  height: 40px;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .tag-logo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  .tag-title {
    font-size: 20px;
    color: #3666b8;
  }
}
.warn-area {
  margin: 0 auto;
  .warn-head {
    max-width: 1300px;
    margin: 0 auto;
    .warn-head-title {
      font-size: 20px;
      color: #2A3537;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
  .warn-list {
    max-width: 1300px;
    margin: 0 auto;
    padding: 40px 70px;
    border-top: 2px solid rgba(113, 216, 242, 0.4);
    border-bottom: 2px solid rgba(113, 216, 242, 0.4);

    .warn-list-item {
      max-width: 1300px;
      height: 98px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 30px;
      box-sizing: border-box;
      margin-bottom: 16px;
      background: rgba(151, 189, 231, 0.6);
      border-left: 6px solid #3396DE;
      &.active {
        border-left: 6px solid #EE191F;
      }
      .img {
        width: 200px;
        height: 98px;
        .el-image {
          width: 200px;
          height: 98px;
        }
        img {
          width: 200px;
          height: 98px;
        }
      }
      .text {
        font-size: 16px;
        padding: 0 10px;
        color: #fff;
        opacity: 0.8;
      }
    }
  }
  .warn-pagination {
    max-width: 1300px;
    margin: 20px auto;
    text-align: right;
  }
}

.login-area {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 2000;
  display: flex;
  flex-direction: column;

  &:hover {
    .dropdown {
      display: inline-flex;
    }
  }

  .dropdown {
    display: none;
    justify-content: flex-start;
    flex-direction: column;
    margin: 10px auto 0;
    border-radius: 6px;
    padding: 6px;
    box-shadow: 0 3px 14px 2px rgba(0, 0, 0, .05), 0 8px 10px 1px rgba(0, 0, 0, 6%), 0 5px 5px -3px rgba(0, 0, 0, 10%);
    background-color: #fff;
    font-size: 14px;
    color: #2a3537;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -20px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom: 10px solid #fff;
    }

    .dropdown-item {
      padding: 4px 10px;
      text-align: center;
      cursor: pointer;
      &:hover {
        background-color: #f3f3f3;
        color: #3396de;
      }
    }
    .dropdown-horizontal {
      height: 0.5px;
      margin: 2px 0;
      background-color: #ccc;
    }
  }

  .user {
    background: #fff;
    padding: 10px 16px;
    border-radius: 22px;
    color: #2a3537;
  }
}

</style>

<style lang="scss">
.el-pagination.is-background .el-pager li {
  min-width: 40px !important;
  height: 40px !important;
  font-size: 16px;
  border: 2px solid #5C6373;
  border-radius: 40px !important;
  font-weight: initial;
  line-height: 36px;
  background-color: rgba(0,0,0,0) !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #3396DE !important;
  color: #fff;
  border: 2px solid #3396DE;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  background: #3396DE !important;
  color: #fff !important;
  border: 2px solid #3396DE;
}
.el-pagination button, .el-pagination span:not([class*=suffix]) {
  height: 40px;
  line-height: 40px;
}
.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: none !important;
  margin: 0px 5px !important;
}
.el-pagination.is-background .btn-prev span {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-right: 12px solid #5C6373;
  border-bottom: 10px solid transparent;
  min-width: auto;
}
.el-pagination.is-background .btn-next span {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 12px solid #5C6373;
  border-bottom: 10px solid transparent;
  min-width: auto;
}
.el-form {
  min-width: 992px;
}
</style>
