<template>
  <div>
    <map-con style="filter: blur(3px)" />
    <div class="header_l">
      <div class="logo">
        <img src="/images/logo.png" alt="logo" />
      </div>
      <div class="tit">黄岛油港区</div>
      <div class="nav">
        <div class="item" @click="handleGo('/forecast')">潮流预报</div>
        <div class="item" @click="handleGo('/antiCollision')">监控预警</div>
        <div class="item" @click="handleGo('/d90')">90泊位预报</div>
      </div>
    </div>
    <div class="header_r">
      <div class="tit">董家口港区</div>
      <div class="nav">
        <div class="item active" @click="handleGo('/d12')">D12泊位预报</div>
      </div>
    </div>
    <div class="ctitle">气象水文预报信息数据来源于《D12泊位精细化船舶作业条件预报预警系统》</div>


    <div class="container">
      <div class="row">
        <div class="col">
          <div class="title">72小时波浪数据预报</div>
          <div class="chart" id="container_wave72"></div>
        </div>
        <div class="col">
          <div class="title">7天波浪数据预报</div>
          <div class="chart" id="container_wave7"></div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="title">72小时海流数据预报</div>
          <div class="chart" id="container_ocean72"></div>
        </div>
        <div class="col">
          <div class="title">7天海流数据预报</div>
          <div class="chart" id="container_ocean7"></div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="title">72小时涌浪数据预报</div>
          <div class="chart" id="container_waveYl72"></div>
        </div>
        <div class="col">
          <div class="title">7天涌浪数据预报</div>
          <div class="chart" id="container_waveYl7"></div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="title">72小时风力数据预报</div>
          <div class="chart" id="container_wind72"></div>
        </div>
        <div class="col">
          <div class="title">7天风力数据预报</div>
          <div class="chart" id="container_wind7"></div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="title">72小时能量预报预警</div>
          <div class="chart" id="container_energy72"></div>
        </div>
        <div class="col">
          <div class="title">船舶作业指数</div>
          <div class="time">{{ judgmentText }}</div>
          <div class="body">
            <div class="item">
              <div class="info">
                <span class="glass">风速(m/s)</span>
                <span class="green">{{ windSpeed }}</span>
              </div>
              <div class="info">
                <span class="glass">风向(方位角)</span>
                <span class="green">{{ windDirection }}</span>
              </div>
            </div>
            <div class="item">
              <div class="info">
                <span class="glass">有效波高(m)</span>
                <span class="green">{{ wave }}</span>
              </div>
              <div class="info">
                <span class="glass">平均周期(s)</span>
                <span class="green">{{ averagePeriod }}</span>
              </div>
            </div>
            <div class="item">
              <div class="info">
                <span class="glass">表层流速(m/s)</span>
                <span class="green">{{ flowVelocity }}</span>
              </div>
              <div class="info">
                <span class="glass">表层流向(方位角)</span>
                <span class="green">{{ flowDirection }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="justify-content: space-around;">
        <div class="col">
          <div class="title">波浪实测数据</div>
          <div class="chart" id="container_wave_rel"></div>
        </div>
        <div class="clear"></div>
      </div>
    </div>

    <div class="login-area">
      <div class="user">
        用户：{{ account
        }}<i class="el-icon-arrow-down" style="color: #3396de"></i>
      </div>
      <div class="dropdown">
        <div class="dropdown-item" @click="handleGo('/reset')">修改密码</div>
        <div class="dropdown-horizontal"></div>
        <div class="dropdown-item" @click="exit">退出登录</div>
      </div>
    </div>
  </div>
</template>

<script>
var colorArr = {
	blue : "#186dfd",
	green : "#50fde5",
	red : "#fd4658",
	yellow : "#f56538",
	orange : "#fcaa52",
	purple : "#cb69f4",
	glass : "#96bdb8",
	dark : "#102334"
};

Date.prototype.format = function (format) {
  var date = {
      "M+": this.getMonth() + 1,
      "d+": this.getDate(),
      "h+": this.getHours(),
      "m+": this.getMinutes(),
      "s+": this.getSeconds(),
      "q+": Math.floor((this.getMonth() + 3) / 3),
      "S+": this.getMilliseconds()
  };
  if (/(y+)/i.test(format)) {
      format = format.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (var k in date) {
      if (new RegExp("(" + k + ")").test(format)) {
          format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? date[k] : ("00" + date[k]).substr(("" + date[k]).length));
      }
  }
  return format;
}
import MapCon from "@/components/map";
import * as echarts from "echarts";
import axios from "axios";
export default {
  name: "D12",
  components: {
    MapCon,
  },
  data() {
    return {
      account: this.$utils.getItem("account"),
      judgmentText: "",
      windSpeed: "",
      windDirection: "",
      wave: "",
      averagePeriod: "",
      flowVelocity: "",
      flowDirection: "",
      baseUrl: "http://10.205.51.106:8088"
      // baseUrl: "http://cws.huaxingocean.com"
    };
  },
  mounted() {
    this.getWaveForecast();
    this.getFlowForecast();
    this.getWindForecast();
    this.getEnergyForecast();
    this.getCurrentForecast();
    this.getWaveRelForecast();
  },
  methods: {
    getWaveForecast() {
      axios({
        url: this.baseUrl + "/api/warnInfoEarly/handleDvsRequest",
        method: "post",
        data: {
          servicename: "forecast",
          subaction: "getWaveForecast",
          "bizcontent[0][a]": 1,
          "bizcontent[0][b]": 2,
          "bizcontent[1][a]": 3,
          "bizcontent[1][b]": 4,
          "bizcontent[2][a]": 5,
          "bizcontent[2][b]": 6,
        },
      }).then((res) => {
        var data = JSON.parse(res.data.replaceAll("&quot;", '"'));
        this.initWave72(data);
        this.initWave7(data);
        this.initWaveYl72(data);
        this.initWaveYl7(data);
      });
    },
    getFlowForecast() {
      axios({
        url: this.baseUrl + "/api/warnInfoEarly/handleDvsRequest",
        method: "post",
        data: {
          servicename: "forecast",
          subaction: "getFlowForecast",
          "bizcontent[0][a]": 1,
          "bizcontent[0][b]": 2,
          "bizcontent[1][a]": 3,
          "bizcontent[1][b]": 4,
          "bizcontent[2][a]": 5,
          "bizcontent[2][b]": 6,
        },
      }).then((res) => {
        var data = JSON.parse(res.data.replaceAll("&quot;", '"'));
        this.initFlow72(data);
        this.initFlow7(data);
      });
    },
    getWindForecast() {
      axios({
        url: this.baseUrl + "/api/warnInfoEarly/handleDvsRequest",
        method: "post",
        data: {
          servicename: "forecast",
          subaction: "getWindForecast",
          "bizcontent[0][a]": 1,
          "bizcontent[0][b]": 2,
          "bizcontent[1][a]": 3,
          "bizcontent[1][b]": 4,
          "bizcontent[2][a]": 5,
          "bizcontent[2][b]": 6,
        },
      }).then((res) => {
        var data = JSON.parse(res.data.replaceAll("&quot;", '"'));
        this.initWind72(data);
        this.initWind7(data);
      });
    },
    getEnergyForecast() {
      axios({
        url: this.baseUrl + "/api/warnInfoEarly/handleDvsRequest",
        method: "post",
        data: {
          servicename: "forecast",
          subaction: "getEnergyForecast",
          "bizcontent": { "flag": "0" }
        },
      }).then((res) => {
        var data = JSON.parse(res.data.replaceAll("&quot;", '"'));
        this.initEnergy72(data);
      });
    },
    getCurrentForecast() {
      axios({
        url: this.baseUrl + "/api/warnInfoEarly/handleDvsRequest",
        method: "post",
        data: {
          servicename: "forecast",
          subaction: "getCurrentForecast",
          "bizcontent": { "zzzt": "3", "date": "" }
        },
      }).then((res) => {
        var data = JSON.parse(res.data.replaceAll("&quot;", '"')).currentForecast;
        var date = new Date(data[0]).format("MM-dd hh:mm");
        if (data[2] == "1") {
          this.judgmentText = `作业条件良好(${date})`;
        } else if (data[2] == "2") {
          this.judgmentText = `基本具备作业条件(${date})`;
        } else if (data[2] == "3") {
          this.judgmentText = `不具备作业条件(${date})`;
        }
        this.windSpeed = data[4];
        this.windDirection = data[3];
        this.wave = data[5];
        this.averagePeriod = data[6];
        this.flowVelocity = data[7];
        this.flowDirection = data[8];
      });
    },
    getWaveRelForecast() {
      axios({
        url: this.baseUrl + "/api/warnInfoEarly/handleDvsRequest",
        method: "post",
        data: {
          servicename: "forecast",
          subaction: "getWaveRel",
          "bizcontent": [{ "a": "1", "b": "2" }, { "a": "3", "b": "4" }, { "a": "5", "b": "6" }]
        },
      }).then((res) => {
        var data = JSON.parse(res.data.replaceAll("&quot;", '"'));
        this.initWaveRel(data);
      });
    },
    // 72小时波浪数据预报
    initWave72(data) {
      var dayArrWave72 = new Array();
      var dom_wave72 = document.getElementById("container_wave72");
      var myChart_wave = echarts.init(dom_wave72);
      var option_wave;
      var waveData;
      waveData = data["waveForecast72"];
      var waveYxbgArr = new Array();
      var waveZqArr = new Array();
      var waveH4Arr = new Array();
      var waveH10Arr = new Array();
      for (var i = 0; i < waveData.length; i++) {
        waveYxbgArr[i] = waveData[i].yxbg;
        waveZqArr[i] = waveData[i].zq;
        waveH4Arr[i] = waveData[i].h4;
        waveH10Arr[i] = waveData[i].zdbg;
        var d = new Date(waveData[i].waveTime).format("hh:mm");
        if (d == "00:00") {
          d = new Date(waveData[i].waveTime).format("MM-dd");
        }
        dayArrWave72[i] = d;
      }
      //获取当前时间，计算X轴开始点
      var d1 = new Date().format("hh");
      var startX = (d1 / 72) * 100;

      option_wave = {
        tooltip: {
          trigger: 'axis',
          formatter: function (tipData) {
              var ddd = new Date(waveData[tipData[0].dataIndex].waveTime).format('MM-dd hh:mm');
              var res = ddd
                  + "<br></waveData.length;i++){>" + "有效波高" + waveData[tipData[0].dataIndex].yxbg
                  + "<br/>" + "4%波高" + waveData[tipData[0].dataIndex].h4
                  + "<br/>" + "最大波高" + waveData[tipData[0].dataIndex].zdbg
                  + "<br/>" + "平均波周期" + waveData[tipData[0].dataIndex].zq;
              return res;
          }
        },
        legend: {
          data: ['有效波高', '4%波高', '最大波高', '平均波周期'],
          textStyle: {
            fontSize: 12,
          },
          selected: {
            '有效波高': false,
            '4%波高': false,
            '最大波高': true,
            '平均波周期': true
          }
        },
        grid: {
          left: '3%',
          right: '1%',
          bottom: '1%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: dayArrWave72,
            splitLine: {
                show: false,
            },
            axisLine: {
              lineStyle: {
                width: 1,
              }
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '              波高：m',
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: "rgb(110,112,121)"
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgb(144,147,154)'
              }  
            },
          },
          {
            type: 'value',
            name: '',
            splitLine: {
              show: false
            },
          },
          {
            type: 'value',
            name: '',
            splitLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
              }
            }
          },
          {
            type: 'value',
            name: '周期：s',
            splitLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: "rgb(110,112,121)"
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgb(144,147,154)'
              }  
            },
          }
        ],
        dataZoom: [{
          show: false,
          start: startX,
          end: 100
        }, {
          type: 'inside'
        }],
        series: [
          {
            name: '有效波高',
            type: 'line',
            yAxisIndex: 0,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: waveYxbgArr
          },
          {
            name: '4%波高',
            type: 'line',
            yAxisIndex: 0,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: waveH4Arr
          },
          {
            name: '最大波高',
            type: 'line',
            yAxisIndex: 0,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: waveH10Arr
          },
          {
            name: '平均波周期',
            type: 'line',
            yAxisIndex: 1,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: waveZqArr
          }
        ]
      };
      if (option_wave && typeof option_wave === "object") {
        myChart_wave.setOption(option_wave, true);
      }
    },
    // 7天波浪数据预报
    initWave7(data) {
      var dayArrWave7 = new Array();
      var dom_wave7 = document.getElementById("container_wave7");
      var myChart_wave7 = echarts.init(dom_wave7);
      var option_wave7;
      var waveData7;
      waveData7 = data['waveForecast7'];
      var waveYxbgArr = new Array();
      var waveZqArr = new Array();
      var waveH4Arr = new Array();
      var waveH10Arr = new Array();
      for (var i = 0; i < waveData7.length; i++) {
        waveYxbgArr[i] = waveData7[i].yxbg;
        waveZqArr[i] = waveData7[i].zq;
        waveH4Arr[i] = waveData7[i].h4;
        waveH10Arr[i] = waveData7[i].zdbg;
        var d = new Date(waveData7[i].waveTime).format('hh:mm');
        if (d == "00:00") {
          d = new Date(waveData7[i].waveTime).format('MM-dd');
        }
        dayArrWave7[i] = d;
      }
      option_wave7 = {
        tooltip: {
          trigger: 'axis',
          formatter: function (tipData) {
            var ddd = new Date(waveData7[tipData[0].dataIndex].waveTime).format('MM-dd hh:mm');
            var res = ddd
                + "<br></waveData7.length;i++){>" + "有效波高" + waveData7[tipData[0].dataIndex].yxbg
                + "<br/>" + "4%波高" + waveData7[tipData[0].dataIndex].h4
                + "<br/>" + "最大波高" + waveData7[tipData[0].dataIndex].zdbg
                + "<br/>" + "平均波周期" + waveData7[tipData[0].dataIndex].zq;
            return res;
          }
        },
        legend: {
          data: ['有效波高', '4%波高', '最大波高', '平均波周期'],
          textStyle: {
            fontSize: 12,
          },
          selected: {
            '有效波高': false,
            '4%波高': false,
            '最大波高': true,
            '平均波周期': true
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: dayArrWave7,
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 1,
              }
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '      波高m',
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: "rgb(110,112,121)"
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgb(144,147,154)'
              }  
            },
          },
          {
            type: 'value',
            name: '',
            splitLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
              }
            }
          },
          {
            type: 'value',
            name: '',
            splitLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
              }
            }
          },
          {
            type: 'value',
            name: '周期：s',
            splitLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: "rgb(110,112,121)"
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgb(144,147,154)'
              }  
            },
          }
        ],
        dataZoom: [{
          show: false,
          start: 0,
          end: 100
        }, {
          type: 'inside'
        }],
        series: [
          {
            name: '有效波高',
            type: 'line',
            yAxisIndex: 0,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: waveYxbgArr
          },
          {
            name: '4%波高',
            type: 'line',
            yAxisIndex: 0,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: waveH4Arr
          },
          {
            name: '最大波高',
            type: 'line',
            yAxisIndex: 0,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: waveH10Arr
          },
          {
            name: '平均波周期',
            type: 'line',
            yAxisIndex: 1,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: waveZqArr
          }
        ]
      };
      if (option_wave7 && typeof option_wave7 === "object") {
        myChart_wave7.setOption(option_wave7, true);
      }

    },
    // 72小时海流数据预报
    initFlow72(data) {
      var dayArrFlow72 = new Array();
      var dom_ocean72 = document.getElementById("container_ocean72");
      var myChart_ocean72 = echarts.init(dom_ocean72);
      var ocean_option72;
      var flowData = data['flowForecast72'];
      var flowArr0 = new Array();
      var flowArr1 = new Array();
      var flowArr2 = new Array();
      var flowArr3 = new Array();
      var flowArr4 = new Array();
      var flowArr5 = new Array();
      var flowArr6 = new Array();
      var flowArr7 = new Array();
      var flowArr8 = new Array();
      var flowArr9 = new Array();
      for (var i = 0; i < flowData.length; i++) {
        flowArr0[i] = flowData[i].ls0;
        flowArr1[i] = flowData[i].ls1;
        flowArr2[i] = flowData[i].ls2;
        flowArr3[i] = flowData[i].ls3;
        flowArr4[i] = flowData[i].ls4;
        flowArr5[i] = flowData[i].ls5;
        flowArr6[i] = flowData[i].ls6;
        flowArr7[i] = flowData[i].ls7;
        flowArr8[i] = flowData[i].ls8;
        flowArr9[i] = flowData[i].ls9;
        var d = new Date(flowData[i].flowTime).format('MM-dd hh:mm');
        dayArrFlow72[i] = d;
      }
      //获取当前时间，计算X轴开始点
      var d1 = new Date().format('hh');
      var startX = d1 / 72 * 100;
      ocean_option72 = {
        tooltip: {
          trigger: 'axis',
          formatter: function (tipData) {
            var ddd = new Date(flowData[tipData[0].dataIndex].flowTime).format('MM-dd hh:mm');
            var res = ddd
                + "<br></flowData.length;i++){>" + "1层流速" + flowData[tipData[0].dataIndex].ls0 + "，流向：" + flowData[tipData[0].dataIndex].lx0
                + "<br/>" + "2层流速" + flowData[tipData[0].dataIndex].ls1 + "，流向：" + flowData[tipData[0].dataIndex].lx1
                + "<br/>" + "3层流速" + flowData[tipData[0].dataIndex].ls2 + "，流向：" + flowData[tipData[0].dataIndex].lx2
                + "<br/>" + "4层流速" + flowData[tipData[0].dataIndex].ls3 + "，流向：" + flowData[tipData[0].dataIndex].lx3
                + "<br/>" + "5层流速" + flowData[tipData[0].dataIndex].ls4 + "，流向：" + flowData[tipData[0].dataIndex].lx4
                + "<br/>" + "6层流速" + flowData[tipData[0].dataIndex].ls5 + "，流向：" + flowData[tipData[0].dataIndex].lx5
                + "<br/>" + "7层流速" + flowData[tipData[0].dataIndex].ls6 + "，流向：" + flowData[tipData[0].dataIndex].lx6
                + "<br/>" + "8层流速" + flowData[tipData[0].dataIndex].ls7 + "，流向：" + flowData[tipData[0].dataIndex].lx7
                + "<br/>" + "9层流速" + flowData[tipData[0].dataIndex].ls8 + "，流向：" + flowData[tipData[0].dataIndex].lx8
                + "<br/>" + "10层流速" + flowData[tipData[0].dataIndex].ls9 + "，流向：" + flowData[tipData[0].dataIndex].lx9;
            return res;
          }
        },
        legend: {
          data: ['1层', '2层', '3层', '4层', '5层', '6层', '7层', '8层', '9层', '10层'],
          textStyle: {
            fontSize: 12,
          },
          selected: {
            '1层': true,
            '2层': false,
            '3层': false,
            '4层': false,
            '5层': true,
            '6层': false,
            '7层': false,
            '8层': false,
            '9层': false,
            '10层': true
          }
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: dayArrFlow72,
            splitLine: {
                show: false,
            },
            axisLine: {
              lineStyle: {
                width: 1,
              }
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '                                    单位：m/s      2米一层，1层为表层',
            splitLine: {
              lineStyle: {
                width: 1,
              }
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: "rgb(110,112,121)"
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgb(144,147,154)'
              }  
            },
            axisTick: {
              show: true
            },
            splitNumber: 4
          }
        ],
        dataZoom: [{
            show: false,
            start: startX,
            end: 100
        }, {
            type: 'inside'
        }],
        series: [
          {
            name: '1层',
            type: 'line',
            lineStyle: {
              width: 2
            },
            data: flowArr0
          },
          {
            name: '2层',
            type: 'line',
            lineStyle: {
                width: 2
            },
            data: flowArr1
          },
          {
            name: '3层',
            type: 'line',
            lineStyle: {
              width: 2
            },
            data: flowArr2
          },
          {
            name: '4层',
            type: 'line',
            lineStyle: {
              width: 2
            },
            data: flowArr3
          },
          {
            name: '5层',
            type: 'line',
            lineStyle: {
              width: 1
            },
            data: flowArr4
          },
          {
            name: '6层',
            type: 'line',
            lineStyle: {
              width: 2
            },
            data: flowArr5
          },
          {
            name: '7层',
            type: 'line',
            lineStyle: {
              width: 2
            },
            data: flowArr6
          },
          {
            name: '8层',
            type: 'line',
            lineStyle: {
              width: 2
            },
            data: flowArr7
          },
          {
            name: '9层',
            type: 'line',
            lineStyle: {
              width: 2
            },
            data: flowArr8
          },
          {
            name: '10层',
            type: 'line',
            lineStyle: {
              width: 2
            },
            data: flowArr9
          }
        ]
      };

      if (ocean_option72 && typeof ocean_option72 === "object") {
        myChart_ocean72.setOption(ocean_option72, true);
      }
    },
    // 7天海流数据预报
    initFlow7(data) {
      var dayArrFlow7 = new Array();
      var dom_ocean7 = document.getElementById("container_ocean7");
      var myChart_ocean7 = echarts.init(dom_ocean7);
      var ocean_option7;
      var flowData = data['flowForecast7'];
      var flowArr0 = new Array();
      var flowArr1 = new Array();
      var flowArr2 = new Array();
      var flowArr3 = new Array();
      var flowArr4 = new Array();
      var flowArr5 = new Array();
      var flowArr6 = new Array();
      var flowArr7 = new Array();
      var flowArr8 = new Array();
      var flowArr9 = new Array();
      for (var i = 0; i < flowData.length; i++) {
        flowArr0[i] = flowData[i].ls0;
        flowArr1[i] = flowData[i].ls1;
        flowArr2[i] = flowData[i].ls2;
        flowArr3[i] = flowData[i].ls3;
        flowArr4[i] = flowData[i].ls4;
        flowArr5[i] = flowData[i].ls5;
        flowArr6[i] = flowData[i].ls6;
        flowArr7[i] = flowData[i].ls7;
        flowArr8[i] = flowData[i].ls8;
        flowArr9[i] = flowData[i].ls9;
        var d = new Date(flowData[i].flowTime).format('MM-dd hh:mm');
        dayArrFlow7[i] = d;
      }
      ocean_option7 = {
        tooltip: {
          trigger: 'axis',
          formatter: function (tipData) {
            var ddd7 = new Date(flowData[tipData[0].dataIndex].flowTime).format('MM-dd hh:mm');
            var res = ddd7
                + "<br></flowData.length;i++){>" + "1层流速" + flowData[tipData[0].dataIndex].ls0 + "，流向：" + flowData[tipData[0].dataIndex].lx0
                + "<br/>" + "2层流速" + flowData[tipData[0].dataIndex].ls1 + "，流向：" + flowData[tipData[0].dataIndex].lx1
                + "<br/>" + "3层流速" + flowData[tipData[0].dataIndex].ls2 + "，流向：" + flowData[tipData[0].dataIndex].lx2
                + "<br/>" + "4层流速" + flowData[tipData[0].dataIndex].ls3 + "，流向：" + flowData[tipData[0].dataIndex].lx3
                + "<br/>" + "5层流速" + flowData[tipData[0].dataIndex].ls4 + "，流向：" + flowData[tipData[0].dataIndex].lx4
                + "<br/>" + "6层流速" + flowData[tipData[0].dataIndex].ls5 + "，流向：" + flowData[tipData[0].dataIndex].lx5
                + "<br/>" + "7层流速" + flowData[tipData[0].dataIndex].ls6 + "，流向：" + flowData[tipData[0].dataIndex].lx6
                + "<br/>" + "8层流速" + flowData[tipData[0].dataIndex].ls7 + "，流向：" + flowData[tipData[0].dataIndex].lx7
                + "<br/>" + "9层流速" + flowData[tipData[0].dataIndex].ls8 + "，流向：" + flowData[tipData[0].dataIndex].lx8
                + "<br/>" + "10层流速" + flowData[tipData[0].dataIndex].ls9 + "，流向：" + flowData[tipData[0].dataIndex].lx9;
            return res;
          }
        },
        legend: {
          data: ['1层', '2层', '3层', '4层', '5层', '6层', '7层', '8层', '9层', '10层'],
          textStyle: {
            fontSize: 12,
          },
          selected: {
            '1层': true,
            '2层': false,
            '3层': false,
            '4层': false,
            '5层': true,
            '6层': false,
            '7层': false,
            '8层': false,
            '9层': false,
            '10层': true
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: dayArrFlow7,
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 1,
              }
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '                                        单位：m/s      2米一层，1层为表层',
            splitLine: {
              lineStyle: {
                width: 1,
              }
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: "rgb(110,112,121)"
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgb(144,147,154)'
              }  
            },
            axisTick: {
              show: true
            },
            splitNumber: 4
          }
        ],
        dataZoom: [{
            show: false,
            start: 0,
            end: 100
        }, {
            type: 'inside'
        }],
        series: [
          {
            name: '1层',
            type: 'line',
            lineStyle: {
              width: 2
            },
            data: flowArr0
          },
          {
            name: '2层',
            type: 'line',
            lineStyle: {
              width: 2
            },
            data: flowArr1
          },
          {
            name: '3层',
            type: 'line',
            lineStyle: {
              width: 2
            },
            data: flowArr2
          },
          {
            name: '4层',
            type: 'line',
            lineStyle: {
                width: 2
            },
            data: flowArr3
          },
          {
            name: '5层',
            type: 'line',
            lineStyle: {
              width: 2
            },
            data: flowArr4
          },
          {
            name: '6层',
            type: 'line',
            lineStyle: {
              width: 2
            },
            data: flowArr5
          },
          {
            name: '7层',
            type: 'line',
            lineStyle: {
              width: 2
            },
            data: flowArr6
          },
          {
              name: '8层',
              type: 'line',
              lineStyle: {
                width: 2
              },
              data: flowArr7
          },
          {
            name: '9层',
            type: 'line',
            lineStyle: {
              width: 2
            },
            data: flowArr8
          },
          {
            name: '10层',
            type: 'line',
            lineStyle: {
              width: 2
            },
            data: flowArr9
          }
        ]
      };
      if (ocean_option7 && typeof ocean_option7 === "object") {
        myChart_ocean7.setOption(ocean_option7, true);
      }
    },
    // 72小时涌浪数据预报
    initWaveYl72(data) {
      var dayArrWaveYl72 = new Array();
      var dom_waveYl72 = document.getElementById("container_waveYl72");
      var myChart_waveYl = echarts.init(dom_waveYl72);
      var option_waveYl;
      var waveData = data['waveYlForecast72'];
      var waveYxbgArr = new Array();
      var waveZqArr = new Array();
      for (var i = 0; i < waveData.length; i++) {
        waveYxbgArr[i] = waveData[i].yxbg;
        waveZqArr[i] = waveData[i].zq;
        var d = new Date(waveData[i].waveTime).format('MM-dd hh:mm');
        dayArrWaveYl72[i] = d;
      }
      //获取当前时间，计算X轴开始点
      var d1 = new Date().format('hh');
      var startX = d1 / 72 * 100;
      option_waveYl = {
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: ['有效波高', '平均波周期'],
          textStyle: {
            fontSize: 12,
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: dayArrWaveYl72,
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 1,
              }
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '                 有效波高：m',
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: "rgb(110,112,121)"
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgb(144,147,154)'
              }  
            },
          },
          {
            type: 'value',
            name: '周期：s',
            splitLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: "rgb(110,112,121)"
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgb(144,147,154)'
              }  
            },
          }
        ],
        dataZoom: [{
            show: false,
            start: startX,
            end: 100
        }, {
            type: 'inside'
        }],
        series: [
          {
            name: '有效波高',
            type: 'line',
            yAxisIndex: 0,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: waveYxbgArr
          },
          {
            name: '平均波周期',
            type: 'line',
            yAxisIndex: 1,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: waveZqArr
          }
        ]
      };

      if (option_waveYl && typeof option_waveYl === "object") {
        myChart_waveYl.setOption(option_waveYl, true);
      }
    },
    // 7天涌浪数据预报
    initWaveYl7(data) {
      var dayArrWaveYl7 = new Array();
      var dom_waveYl7 = document.getElementById("container_waveYl7");
      var myChart_waveYl7 = echarts.init(dom_waveYl7);
      var option_waveYl7;
      var waveData = data['waveYlForecast7'];
      var waveYxbgArr = new Array();
      var waveZqArr = new Array();
      for (var i = 0; i < waveData.length; i++) {
        waveYxbgArr[i] = waveData[i].yxbg;
        waveZqArr[i] = waveData[i].zq;
        var d = new Date(waveData[i].waveTime).format('MM-dd hh:mm');
        dayArrWaveYl7[i] = d;
      }
      option_waveYl7 = {
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: ['有效波高', '平均波周期'],
          textStyle: {
            fontSize: 12,
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: dayArrWaveYl7,
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 1,
              }
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '      有效波高m',
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: "rgb(110,112,121)"
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgb(144,147,154)'
              }  
            },
          },
          {
            type: 'value',
            name: '周期：s',
            splitLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: "rgb(110,112,121)"
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgb(144,147,154)'
              }  
            },
          }
        ],
        dataZoom: [{
            show: false,
            start: 0,
            end: 100
        }, {
            type: 'inside'
        }],
        series: [
          {
            name: '有效波高',
            type: 'line',
            yAxisIndex: 0,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: waveYxbgArr
          },
          {
            name: '平均波周期',
            type: 'line',
            yAxisIndex: 1,
            smooth: true,
            lineStyle: {
                width: 2
            },
            data: waveZqArr
          }
        ]
      };

      if (option_waveYl7 && typeof option_waveYl7 === "object") {
        myChart_waveYl7.setOption(option_waveYl7, true);
      }
    },
    // 72小时风力数据预报
    initWind72(data) {
      var dayArrWind72 = new Array();
      var dom_wind72 = document.getElementById("container_wind72");
      var myChart_wind72 = echarts.init(dom_wind72);
      var wind_option72;
      var windData = data['windForecast72'];
      var windArr = new Array();
      for (var i = 0; i < windData.length; i++) {
        windArr[i] = windData[i].fl;
        var d = new Date(windData[i].windTime).format('MM-dd hh:mm');
        dayArrWind72[i] = d;
      }
      //获取当前时间，计算X轴开始点
      var d1 = new Date().format('hh');
      var startX = d1 / 72 * 100;
      wind_option72 = {
        tooltip: {
          trigger: 'axis',
          formatter: function (tipData) {
            var ddd = new Date(windData[tipData[0].dataIndex].windTime).format('MM-dd hh:mm');
            var res = ddd + "<br></windData.length;i++){>风向：" + windData[tipData[0].dataIndex].fx + "，风速：" + windData[tipData[0].dataIndex].fs + "m/s";
            return res;
          }
        },
        legend: {
          data: ['风力等级'],
          textStyle: {
            fontSize: 12,
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: dayArrWind72,
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 1,
              }
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '    单位：级',
            splitLine: {
              lineStyle: {
                width: 1,
              }
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: "rgb(110,112,121)"
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgb(144,147,154)'
              }  
            },
            axisTick: {
              show: true
            },
            splitNumber: 4
          }
        ],
        dataZoom: [{
            show: false,
            start: startX,
            end: 100
        }, {
            type: 'inside'
        }],
        series: [
          {
            name: '风力等级',
            type: 'line',
            yAxisIndex: 0,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: windArr
          }
        ]
      };
      if (wind_option72 && typeof wind_option72 === "object") {
        myChart_wind72.setOption(wind_option72, true);
      }
    },
    // 7天风力数据预报
    initWind7(data) {
      var dayArrWind7 = new Array();
      var dom_wind7 = document.getElementById("container_wind7");
      var myChart_wind7 = echarts.init(dom_wind7);
      var wind_option7;
      var windData = data['windForecast7'];
      var windArr = new Array();
      for (var i = 0; i < windData.length; i++) {
        windArr[i] = windData[i].fl;
        var d = new Date(windData[i].windTime).format('MM-dd hh:mm');
        dayArrWind7[i] = d;
      }
      wind_option7 = {
        tooltip: {
          trigger: 'axis',
          formatter: function (tipData) {
            var ddd = new Date(windData[tipData[0].dataIndex].windTime).format('MM-dd hh:mm');
            var res = ddd + "<br></windData.length;i++){>风向：" + windData[tipData[0].dataIndex].fx + "，风速：" + windData[tipData[0].dataIndex].fs + "m/s";
            return res;
          }
        },
        legend: {
          data: ['风力等级'],
          textStyle: {
            fontSize: 12,
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: dayArrWind7,
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 1,
              }
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '    单位：级',
            splitLine: {
              lineStyle: {
                width: 1,
              }
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: "rgb(110,112,121)"
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgb(144,147,154)'
              }  
            },
            axisTick: {
              show: true
            },
            splitNumber: 4
          }
        ],
        dataZoom: [{
            show: false,
            start: 0,
            end: 100
        }, {
            type: 'inside'
        }],
        series: [
          {
            name: '风力等级',
            type: 'line',
            yAxisIndex: 0,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: windArr
          }
        ]
      };
      if (wind_option7 && typeof wind_option7 === "object") {
        myChart_wind7.setOption(wind_option7, true);
      }
    },
    // 72小时能量预报预警
    initEnergy72(data) {
      var dayArrEnergy72 = new Array();
      var energy_dom72 = document.getElementById("container_energy72");
      var myChart_energy72 = echarts.init(energy_dom72);
      var energy_option72;
      var energyData;
      energyData = data['energyForecast72'];
      var energyStateArr = new Array();
      for (var i = 0; i < energyData.length; i++) {
        energyStateArr[i] = energyData[i].energyIndex;
        var d = new Date(energyData[i].energyTime).format('MM-dd hh:mm');
        dayArrEnergy72[i] = d;
      }
      //获取当前时间，计算X轴开始点
      var d1 = new Date().format('hh');
      var startX = d1 / 72 * 100;
      energy_option72 = {
          title: {
              text: '0-4.5作业条件良好，4.6-6基本具备作业条件，6.1-25不具备作业条件，25以上逃离码头',
              textStyle: {
                color: colorArr.yellow,
                fontSize: 12,
                fontWeight: 'bold',
              },
              top: 'top',
              left: 'center'
          },
          tooltip: {
              trigger: 'axis',
              formatter: function (tipData) {
                  var ddd = new Date(energyData[tipData[0].dataIndex].energyTime).format('MM-dd hh:mm');
                  var res = ddd
                      + "<br></energyData.length;i++){>" + "能量指数" + energyData[tipData[0].dataIndex].energyIndex;
                  return res;
              }
          },
          grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
          },
          xAxis: [
              {
                  type: 'category',
                  boundaryGap: false,
                  data: dayArrEnergy72,
                  splitLine: {
                      show: false,
                  },
                  axisLine: {
                      lineStyle: {
                          width: 1,
                      }
                  },
                  axisLabel: {
                      textStyle: {
                          fontSize: 12,
                      }
                  }
              }
          ],
          yAxis: [
            {
              type: 'value',
              name: '            单位：能量指数',
              splitLine: {
                lineStyle: {
                  width: 1,
                }
              },
              axisLine: {
                show: false
              },
              axisLabel: {
                textStyle: {
                  fontSize: 12,
                  color: "rgb(110,112,121)"
                }
              },
              axisLine: {
                show: true,
                lineStyle: {
                  type: 'solid',
                  color: 'rgb(144,147,154)'
                }  
              },
              axisTick: {
                show: true
              },
              //splitNumber : 10,
              //min:0,
              //max:10
            }

          ],
          dataZoom: [{
              show: false,
              start: startX,
              end: 100
          }, {
              type: 'inside'
          }],
          color: ['#87CEFA', '#FFFF00', '#FF8C00', '#FF0000'],
          series: [
              {
                  name: '蓝色预警',
                  type: 'line',
                  animation: false,
                  areaStyle: {
                      normal: {}
                  },
                  lineStyle: {
                      normal: {
                          width: 2
                      }
                  },
                  markArea: {
                      data: [[{
                          yAxis: '0'
                      }, {
                          yAxis: '4.5'
                      }]]
                  },
              }
              , {
                  name: '黄色预警',
                  type: 'line',
                  animation: false,
                  areaStyle: {
                      normal: {}
                  },
                  lineStyle: {
                      normal: {
                          width: 2
                      }
                  },
                  markArea: {
                      data: [[{
                          yAxis: '4.6'
                      }, {
                          yAxis: '6'
                      }]]
                  }
              }
              , {
                  name: '橙色预警',
                  type: 'line',
                  animation: false,
                  areaStyle: {
                      normal: {}
                  },
                  lineStyle: {
                      normal: {
                          width: 2
                      }
                  },
                  markArea: {
                      data: [[{
                          yAxis: '6.1'
                      }, {
                          yAxis: '25'
                      }]]
                  }
              }
              , {
                  name: '红色预警',
                  type: 'line',
                  animation: false,
                  areaStyle: {
                      normal: {}
                  },
                  lineStyle: {
                      normal: {
                          width: 2
                      }
                  },
                  markArea: {
                      data: [[{
                          yAxis: '25.1'
                      }, {
                          yAxis: '40'
                      }]]
                  }
              },
              {
                  name: '故障发生概率',
                  type: 'line',
                  data: energyStateArr,
                  itemStyle: {
                      normal: {
                          lineStyle: {
                              color: '#00FF00'
                          }
                      }
                  },
              },
          ],
      };
      if (energy_option72 && typeof energy_option72 === "object") {
        myChart_energy72.setOption(energy_option72, true);
      }
    },
    // 波浪实测数据
    initWaveRel(data) {
      var dayArrWaveRel = new Array();
      var dom_wave_rel = document.getElementById("container_wave_rel");
      var myChart_wave_rel = echarts.init(dom_wave_rel);
      var option_wave_rel;
      var waveRelData;
      waveRelData = data['waveRel'];
      var waveYxbgArr = new Array();
      var waveZdbgArr = new Array();
      for (var i = 0; i < waveRelData.length; i++) {
        waveYxbgArr[i] = waveRelData[i].yxbg;
        waveZdbgArr[i] = waveRelData[i].zdbg;
        var d = new Date(waveRelData[i].waveTime).format('hh:mm');
        if (d == "00:00") {
          d = new Date(waveRelData[i].waveTime).format('MM-dd');
        }
        dayArrWaveRel[i] = d;
      }
      //获取当前时间，计算X轴开始点
      var d1 = new Date().format('hh');
      var startX = d1 / 24 * 100;
      option_wave_rel = {
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: ['有效波高', '最大波高'],
          textStyle: {
            fontSize: 12,
          }
        },
        grid: {
          left: '3%',
          right: '5%',
          bottom: '1%',
          width: '95%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: dayArrWaveRel,
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 1,
              }
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '              波高：m',
            axisLine: {
              show: false
            },
            axisTick: {
              show: true
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: "rgb(110,112,121)"
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgb(144,147,154)'
              }  
            },
          }
        ],
        dataZoom: [{
            show: false,
            start: 0,
            end: 100
        }, {
            type: 'inside'
        }],
        series: [
          {
            name: '有效波高',
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: waveYxbgArr
          },
          {
            name: '最大波高',
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: waveZdbgArr
          }
        ]
      };
      if (option_wave_rel && typeof option_wave_rel === "object") {
        myChart_wave_rel.setOption(option_wave_rel, true);
      }
    },
    handleGo(path) {
      this.$router.push({ path });
      var antiCollision = this.$route.fullPath;
      if (antiCollision == "/antiCollision") {
        window.location.reload();
      }
    },
    exit() {
      this.$utils.clearItem();
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style lang="scss" scoped>
.ctitle {
  position: fixed;
  top: 100px;
  left: 36px;
  font-size: 16px;
  z-index: 2222;
  color: #555;
}
.row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  .col {
    width: 660px;
    height: 340px;
    margin-bottom: 30px;
    background-color: rgba(255, 255, 255, 0.75);
  }
  .title {
    font-size: 18px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .chart {
    margin: 0 auto;
    width: 620px;
    height: 280px;
  }
  .time {
    padding: 10px 20px;
    text-align: center;
    font-size: 18px;
    color: #000;
    border-bottom: 1px solid #000;
  }
  .body {
    padding: 20px 40px;
    .item {
      height: 70px;
      .info {
        display: inline-flex;
        align-items: center;
        width: 50%;
        height: 70px;
        .glass {
          display: inline-block;
          width: 50%;
          font-size: 16px;
          color: #000;
        }
        .green {
          display: inline-block;
          width: 50%;
          font-size: 16px;
          color: #000;
        }
      }
    }
  }
  .clear {
    display: table;
    clear: both;
    content: "";
    width: 660px;
    min-height: 1px;
  }
}
.container {
  display: flex;
  flex-direction: column;
  padding-top: 150px;
  position: relative;
  z-index: 2000;
}

.header_l,
.header_r {
  z-index: 20000;
  position: fixed;
  top: 30px;
  height: 52px;
  background-color: #fff;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    padding-left: 18px;
    img {
      width: 226px;
    }
  }
  .tit {
    font-size: 24px;
    margin: 0 10px;
    font-family: cursive;
    font-weight: bold;
  }
  .nav {
    display: flex;
    align-items: center;
    justify-content: center;
    .item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 52px;
      border-radius: 30px;
      font-size: 16px;
      color: #2a3537;
      cursor: pointer;
      &.active {
        background-color: #3396de;
        color: #fff;
      }
    }
  }
}
.header_l {
  left: 30px;
}
.header_r {
  left: 900px;
  .tit {
    margin-left: 16px;
  }
}
.login-area {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 2000;
  display: flex;
  flex-direction: column;

  &:hover {
    .dropdown {
      display: inline-flex;
    }
  }

  .dropdown {
    display: none;
    justify-content: flex-start;
    flex-direction: column;
    margin: 10px auto 0;
    border-radius: 6px;
    padding: 6px;
    box-shadow: 0 3px 14px 2px rgba(0, 0, 0, .05), 0 8px 10px 1px rgba(0, 0, 0, 6%), 0 5px 5px -3px rgba(0, 0, 0, 10%);
    background-color: #fff;
    font-size: 14px;
    color: #2a3537;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -20px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom: 10px solid #fff;
    }

    .dropdown-item {
      padding: 4px 10px;
      text-align: center;
      cursor: pointer;
      &:hover {
        background-color: #f3f3f3;
        color: #3396de;
      }
    }
    .dropdown-horizontal {
      height: 0.5px;
      margin: 2px 0;
      background-color: #ccc;
    }
  }

  .user {
    background: #fff;
    padding: 10px 16px;
    border-radius: 22px;
    color: #2a3537;
  }
}
</style>

