export default {
    navName: {
        forecast: "潮流预报",
        antiCollision: "监控预警",
    },
    layer: {
        surface: "表层",
        middle: "中层",
        bottom: "底层",
    },
    more: "查看更多",
    berthPrediction: "泊位预报",
    user: {
        name: "用户",
        LogOut: '退出登录',
        reset: "修改密码",
    },
    month: "月",
    day: "日",
    lng: "经度",
    lat: "纬度",
    meter: "米",
    waterDepth: "水深",
    chartTitle: "点位流速流向",
    marksDate: "{month}月{day}日",
    hour: "时",
    velocity: "流速",
    direction: "流向",
    directArr: ({ values }) => {
        let arr = [
            "北", "北东北", "东北", "东东北", 
            "东", "东东南", "东南", "南东南",
            "南", "南西南", "西南", "西西南",
            "西", "西西北", "西北", "北西北"
        ];
        return arr[values.index]
    },
    vsam: "视频监控",
    Lidar: "雷达",
    position: "点位",
    maxVelocity: "最大流速",
    time: "发生时刻",
    highestWaterLevels: "最高水位",
    lowestWaterLevels: "最低水位",
    information: "告警信息",
    nextHours: "未来14小时",
}