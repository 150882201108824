import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Forecast from "../views/Forecast.vue";
import AntiCollision from "../views/Anti-collision.vue";
import Warn from "../views/Warn.vue";
import WarnInfo from "../views/WarnInfo.vue";
import d12 from "../views/D12.vue";
import d90 from "../views/D90.vue";
import Reset from "../views/Reset.vue"
import utils from "../utils/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "forecast",
    component: Forecast,
    meta: { requiresAuth: true }
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/reset",
    name: "Reset",
    component: Reset,
    meta: { requiresAuth: true }
  },
  {
    path: "/forecast",
    name: "forecast",
    component: Forecast,
    meta: { requiresAuth: true }
  },
  {
    path: "/antiCollision",
    name: "antiCollision",
    component: AntiCollision,
    meta: { requiresAuth: true }
  },
  {
    path: "/warn",
    name: "warn",
    component: Warn,
    meta: { requiresAuth: true }
  },
  {
    path: "/warnInfo",
    name: "warnInfo",
    component: WarnInfo,
    meta: { requiresAuth: true }
  },
  {
    path: "/d12",
    name: "d12",
    component: d12,
    meta: { requiresAuth: true }
  },
  {
    path: "/d90",
    name: "d90",
    component: d90,
    meta: { requiresAuth: true }
  },
  {
    path: "*",
    redirect: '/login'
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !utils.getItem("X-Admin-Token")) next({ name: 'Login' })
  else next();
})

export default router;