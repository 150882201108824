<template>
  <div class="login-view" style="background: url(/images/img-bg.jpg) no-repeat 100%;background-size: cover;">
    <div class="header">
      <div class="nav-header">
        <div class="nav-logo">
          <img src="/images/logo.png" alt="">
        </div>
      </div>
    </div>
    <div class="tit">
      <p>山东港口青岛港黄岛油港区</p>
      <p>潮流精细化预报及监控预警报警系统</p>
    </div>
    <div class="main">
      <div class="container">
        <div class="user-from-box">
          <div class="user-from-title">
            <div class="title">欢迎登录</div>
            <div class="en-text">Welcome to Login</div>
          </div>
          <div class="user-from">
            <div class="from-cell">
              <div class="from-label">用户名<span> *</span></div>
              <div class="from-control">
                <input type="text" v-model="username" placeholder="请输入用户名">
              </div>
            </div>
            <div class="from-cell">
              <div class="from-label">密码<span> *</span></div>
              <div class="from-control">
                <input type="password" v-model="password" placeholder="请输入密码">
              </div>
            </div>
            <div class="from-cell">
              <button class="btn" @click="login">登录</button>
            </div>
            <div class="bottom">
              <div class="download-button">
                <span>下载APP</span>
                <div class="dl-app">
                  <img src="/images/qrcode.png" >
                  <div class="promote">扫码下载APP</div>
                </div>
              </div>
              <div class="from-link" v-if="false">
                <router-link to="/reset">修改密码</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: ""
    }
  },
  methods: {
    login() {
      if (this.username == '') {
        return this.$message({
          message: '请输入用户名',
          type: 'warning'
        });
      }
      if (this.password == '') {
        return this.$message({
          message: '请输入密码',
          type: 'warning'
        });
      }
      let params = {
        account: this.username,
        password: this.password
      }
      axios({
        url: this.$C.HTTP_REQUEST_URL + "/api/login_new_web",
        method: "post",
        data: params
      }).then(res => {
        if (res.data.status == 200) {
          if (res.data.data.overtime_status == 1) {
            this.$confirm('您已经超过三个月未修改登录密码', '提示', {
              confirmButtonText: '去修改',
              cancelButtonText: '取消',
              showClose: false,
              closeOnClickModal: false,
              closeOnPressEscape: false
            }).then((result) => {
              this.$utils.setItem("X-Admin-Token", res.data.data.token);
              this.$utils.setItem("account", res.data.data.account);
              this.$router.push({ path: '/reset' });
            }).catch((err) => {
              this.$utils.setItem("X-Admin-Token", res.data.data.token);
              this.$utils.setItem("account", res.data.data.account);
              this.$router.push({ path: '/' });
            });
          } else {
            this.$utils.setItem("X-Admin-Token", res.data.data.token);
              this.$utils.setItem("account", res.data.data.account);
              this.$router.push({ path: '/' });
          }
        } else {
          return this.$message({
            message: res.data.msg,
            type: 'warning'
          });
        }
      })
    },
    handleGo(path) {
      this.$router.push({ path });
    }
  }
};
</script>

<style lang="scss" scoped>
.login-view {
  width: 100%;
  height: calc(100vh);
  background-color: aquamarine;
}

.header {
  width: 100%;

  .nav-header {
    padding: 55px 55px;

    .nav-logo {
      img {
        width: 340px;
      }
    }
  }
}

.main {
  .container {

    .user-from-box {
      width: 440px;
      // height: 416px;
      height: 376px;
      border-radius: 16px;
      background-color: #fff;
      padding-top: 30px;
      padding-bottom: 34px;
      float: right;
      margin-right: 7%;
      position: relative;
    }

    .user-from-title {
      margin-bottom: 40px;

      .title {
        text-align: center;
        font-size: 32px;
        color: #2A3537;
        letter-spacing: 0.15em;
        margin-bottom: 10px;
      }

      .en-text {
        text-align: center;
        font-size: 16px;
        color: #A3AED0;
        letter-spacing: 0.55em;
      }
    }

    .user-from {
      margin: 0 auto;

      .from-cell {
        margin: 0 auto;
        width: 350px;

        .from-label {
          font-size: 16px;
          color: #2A3537;
          margin-bottom: 10px;

          span {
            color: #3396DE;
          }
        }

        .from-control {
          width: 350px;
          height: 40px;
          border: 1px solid #E0E5F2;
          border-radius: 6px;
          margin-bottom: 20px;
          padding: 4px 18px;
          box-sizing: border-box;

          input {
            font-size: 14px;
            color: #333;
            border: none;
            line-height: initial;
            height: 100%;
            width: 100%;
          }
        }

        .btn {
          width: 350px;
          height: 42px;
          background-color: #3396DE;
          font-size: 18px;
          color: #fff;
          text-align: center;
          line-height: 42px;
          border: none;
          cursor: pointer;
          border-radius: 27px;
          margin-top: 10px;
          margin-bottom: 20px;
        }


      }
      .from-link {
        text-align: right;
        a {
          color: #999;
          font-size: 14px;
          &:hover {
            color: #3396DE;
          }
        }
      }

    }

    .user-from-bottom {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 50px;
        margin-right: 6px;
      }

      span {
        font-size: 20px;
        color: #3666B8;
      }
    }

    .bottom {
      margin: 0 auto;
      width: 350px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .download-button {
        position: relative;
        color: #999;
        cursor: pointer;
        &:hover .dl-app {
          display: block;
        }
      }
    }

    .dl-app {
      display: none;
      position: absolute;
      bottom: -10px;
      left: -190px;
      width: 134px;
      height: 158px;
      padding: 8px;
      background: #fff;
      box-sizing: border-box;

      img {
        width: 118px;
        height: 118px;
      }
      .promote {
        font-size: 14px;
        color: #333;
        text-align: center;
      }
    }
  }
}

.tit {
  position: absolute;
  top: 50px;
  font-size: 44px;
  color: #fff;
  right: 5%;
  opacity: 0.9;
  font-family: cursive;
  text-align: center;
}
</style>
