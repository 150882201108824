import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import utils from "./utils";
import map from "./utils/map";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from "axios";
import jquery from "jquery";
import "leaflet/dist/leaflet.css";
import config from "./config/config";
import i18n from './lang';

Vue.config.productionTip = false;
Vue.prototype.$utils = utils;
Vue.prototype.$M = map;
Vue.prototype.$axios = axios;
Vue.prototype.$ = jquery;
Vue.prototype.$C = config;

// 自定义弹出窗口
require('@/utils/L.CustomPopup');

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)  //当切换 locale 的时候，t()函数会根据传入的key值去locale指定的语言包中找响应的值
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
