<template>
  <div class="map-layout">
    <div class="header_l">
      <div class="logo">
        <img src="/images/logo.png" alt="logo" />
      </div>
      <div class="tit">黄岛油港区</div>
      <div class="nav">
        <div class="item active">{{ $t('navName.forecast') }}</div>
        <div class="item" @click="handleGo('/antiCollision')">{{ $t('navName.antiCollision') }}</div>
        <div class="item" @click="handleGo('/d90')">90{{ $t('berthPrediction') }}</div>
      </div>
    </div>
    <div class="header_r">
      <div class="tit">董家口港区</div>
      <div class="nav">
        <div class="item" @click="handleGo('/d12')">D12{{ $t('berthPrediction') }}</div>
      </div>
    </div>

    <div class="map-container" ref="map" id="map-container"></div>

    <div class="login-area">
      <div class="user">
        {{ $t('user.name') }}：{{ account
        }}<i class="el-icon-arrow-down" style="color: #3396de"></i>
      </div>
      <div class="dropdown">
        <div class="dropdown-item" @click="handleGo('/reset')">{{ $t('user.reset') }}</div>
        <div class="dropdown-item" @click="changeLanguage()">中/EN</div>
        <div class="dropdown-horizontal"></div>
        <div class="dropdown-item" @click="exit">{{ $t('user.LogOut') }}</div>
      </div>
    </div>

    <div class="sidebar">
      <div class="marker-area" v-if="false">
        <template v-for="(item, index) in pointList">
          <div class="item" :key="item.id" @click="bindPointClick(index)">
            <div class="id">{{ item.id }}</div>
            <div class="velocity">{{ item.velocity }}</div>
            <div class="direction">{{ item.direction }}</div>
          </div>
        </template>
      </div>
    </div>

    <div class="level">
      <div class="item" :class="{ active: level <= 5, 'no-radius': level < 5 }" @click="changeLevel(5)">{{ $t('layer.surface') }}</div>
      <div class="item" :class="{ active: level <= 3, 'no-radius': level < 3 }" @click="changeLevel(3)">{{ $t('layer.middle') }}</div>
      <div class="item" :class="{ active: level == 1 }" @click="changeLevel(1)">{{ $t('layer.bottom') }}</div>
    </div>

    <div class="bottom">
      <div class="play-back-action">
        <div class="play-back-action-btn" @click="play">
          <span class="icon iconfont icon-bofang" v-if="!isPlay"></span>
          <span class="icon iconfont icon-zanting" v-if="isPlay"></span>
        </div>
        <div class="more" @click="handleGo('/warnInfo')">{{ $t('more') }}</div>
        <div class="play-back-action-calendar">
          <el-date-picker
            v-model="checkDateEnd"
            type="date"
            :editable="false"
            placeholder="请选择日期"
            :picker-options="pickerOptions"
            :clearable="false"
            @change="changeDate"
          />
        </div>
      </div>
      <div class="play-back-progress">
        <p class="play-back-time">{{nowTime}}</p>
        <el-slider
          v-model="sliderNo"
          :max="71"
          :marks="marks"
          :format-tooltip="formatTooltip"
          @change="changeSlider"
        />
      </div>
    </div>

  </div>
</template>

<script>
import * as echarts from "echarts";
import axios from "axios";
import utils from "@/utils/index";
import Cookie from 'js-cookie';
export default {
  name: "Forecast",
  data() {
    return {
      account: this.$utils.getItem("account"),
      map: null,
      // OSMUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      // OSMUrl: "/{z}/{x}/{y}.png",
      // OSMUrl: "http://yztmap.qdport.com:6080/arcgis/rest/services/qdportsea/MapServer/{z}/{x}/{y}.jpg",
      OSMUrl: "/newtask/{z}/{x}/{y}.png",
      /** 地图初始化时的中心点位置 */
      mapCenter: [36.07161448922266, 120.2281951904297],
      /** 点位信息 */
      pointList: [],
      /** marker标记点数组 */
      markerList: [],
      /** 时间控件 */
      checkDateEnd: null,
      level: 5,
      sliderNo: 0,
      marks: {
        0: "0",
        6: "06时",
        12: "12时",
        18: "18时",
        24: "0",
        30: "06时",
        36: "12时",
        42: "18时",
        48: "0",
        54: "06时",
        60: "12时",
        66: "18时",
        71: "23时",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      layer: null,
      isPlay: false,
      timer: null,
      innerTimer: null,
      startYear: "",
      startMonth: "",
      startDay: "",
      startHour: "00",
      startMinutes: "00",

      nowYear: "",
      nowMonth: "",
      nowDay: "",
      nowHour: "",
      nowMinutes: "",
      nowTime: "",

      oldYear: "",
      oldMonth: "",
      oldDay: "",
      oldHour: "",
      oldMinutes: "",

      endYear: "",
      endMonth: "",
      endDay: "",
      endHour: "23",
      endMinutes: "50",
      id: "",
      pid: "",
      flowto: [],
      speed: [],
      cdate: [],
      myChart: null,
      directArr: [
        "北", "北东北", "东北", "东东北", 
        "东", "东东南", "东南", "南东南",
        "南", "南西南", "西南", "西西南",
        "西", "西西北", "西北", "北西北"
      ],
      cell: 1
    };
  },
  mounted() {
    this.initDate();
    /** 创建地图 */
    this.createMap();
    let that = this;
    this.map.on("popupopen", function (e) {
      if($("#btnCon").length > 0) {
        $("#btnCon").onclick = function (e) {
          that.cell = 1;
          if(that.pid == '9') {
            that.$(".cell-box").show()
            that.$(".cell").eq(0).addClass("active");
          }else {
            that.$(".cell-box").hide()
          }
          that.open();
        };
      }
    });
    this.map.on("moveend", (e) => {
      that.cell = 1;
      if(that.pid == '9') {
        that.$(".cell-box").show()
        that.$(".cell").eq(0).addClass("active");
      }else {
        that.$(".cell-box").hide()
      }
      if (this.layer) {
        console.log(e)
        console.log(this.layer)
        this.layer.remove(this.map);
        this.layer.addTo(this.map);
      }
      //新加判断弹框折线图
      if (this.myChart) {
        let is_btnCon = document.getElementById("btnCon");
        if (is_btnCon != null) {
          //存在，正常处理
          document.getElementById("btnCon").onclick = function (e) {
            that.open();
          };
        }
      }
      var cell = document.getElementsByClassName("cell");
      for(var i = 0;i < cell.length;i++){
          cell[i].onclick= function(e){
              that.$(".cell").removeClass("active no-radius");
              that.cell = that.$(this).data("cell");
              that.$(this).addClass("active");
              that.getWarnShip();
          };
      }
    });

    this.map.on("click", (e) => {
      let lat = Number(e.latlng.lat).toFixed(8);
      let lng = Number(e.latlng.lng).toFixed(8);
      axios({
        url: this.$C.HTTP_REQUEST_URL + "/api/warnMap/water_depth",
        method: "get",
        params: {
          longitude: lng,
          latitude: lat,
        }
      }).then(res => {
        if(res && res.data && res.data.status == 200) {
          L.popup()
            .setLatLng(e.latlng)
            .setContent(
              `${this.$t('lat')}：${utils.ToDegrees(e.latlng.lat + '')}N<br />
              ${this.$t('lng')}：${utils.ToDegrees(e.latlng.lng + '')}E<br />
              ${this.$t('waterDepth')}：${res.data.data.water_depth}${this.$t('meter')}`
            )
            .openOn(this.map);
        }
      })
    });

  },
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.innerTimer);
  },
  methods: {
    formatTooltip(val) {
      let startDate = this.getStartDate(
          `${this.startYear}-${this.startMonth}-${this.startDay} ${this.startHour}:${this.startMinutes}:00`
        );
        startDate.date.setHours(startDate.date.getHours() + val);
        var year = startDate.date.getFullYear();
        var month = startDate.date.getMonth() + 1;
        var day = startDate.date.getDate();
        var hour = startDate.date.getHours();
        month = month.toString().padStart(2, "0");
        day = day.toString().padStart(2, "0");
        hour = hour.toString().padStart(2, "0");
      return `${year}-${month}-${day} ${hour}:00:00`;
    },
    /** 初始化时间 */
    initDate() {
      this.marks = {
        0: "0",
        6: `06${this.$t('hour')}`,
        12: `12${this.$t('hour')}`,
        18: `18${this.$t('hour')}`,
        24: "0",
        30: `06${this.$t('hour')}`,
        36: `12${this.$t('hour')}`,
        42: `18${this.$t('hour')}`,
        48: "0",
        54: `06${this.$t('hour')}`,
        60: `12${this.$t('hour')}`,
        66: `18${this.$t('hour')}`,
        71: `23${this.$t('hour')}`,
      }
      let startDate = this.getStartDate();
      this.startYear = startDate.year;
      this.startMonth = startDate.month;
      this.startDay = startDate.day;
      this.checkDateEnd = `${startDate.year}-${startDate.month}-${startDate.day}`;

      let nowDate = this.getNowDate();
      this.sliderNo = nowDate.date.getHours();
      this.nowYear = nowDate.year;
      this.nowMonth = nowDate.month;
      this.nowDay = nowDate.day;
      this.nowHour = nowDate.hour;
      this.nowMinutes = nowDate.minutes;

      let endDate = this.getEndDate(
        this.startYear,
        this.startMonth,
        this.startDay
      );
      this.endYear = endDate.year;
      this.endMonth = endDate.month;
      this.endDay = endDate.day;
      this.setSliderDate();
    },

    setSliderDate() {
      this.marks[0] = this.$t('marksDate', { month: this.startMonth, day: this.startDay });
      (() => {
        var date = new Date(`${this.startYear}-${this.startMonth}-${this.startDay} 00:00:00`);
        date.setDate(date.getDate()+1);
        var day = date.getDate();
        day = day.toString().padStart(2, "0");
        var month = date.getMonth();
        month = month + 1;
        month = month.toString().padStart(2, "0");
        this.marks[24] = this.$t('marksDate', { month: month, day: day });
      })();
      (() => {
        var date = new Date(`${this.startYear}-${this.startMonth}-${this.startDay} 00:00:00`);
        date.setDate(date.getDate()+2);
        var day = date.getDate();
        day = day.toString().padStart(2, "0");
        var month = date.getMonth();
        month = month + 1;
        month = month.toString().padStart(2, "0");
        this.marks[48] = this.$t('marksDate', { month: month, day: day });
      })();
    },

    /** 获取开始时间 */
    getStartDate(time) {
      var startDate;
      if (time) {
        startDate = new Date(time);
      } else {
        startDate = new Date();
      }
      startDate.setHours(0);
      startDate.setMinutes(0);
      var startYear = startDate.getFullYear();
      var startMonth = startDate.getMonth();
      var startDay = startDate.getDate();
      startMonth = startMonth + 1;
      startMonth = startMonth.toString().padStart(2, "0");
      startDay = startDay.toString().padStart(2, "0");
      return {
        date: startDate,
        year: startYear,
        month: startMonth,
        day: startDay,
      };
    },

    /** 获取当前时间 */
    getNowDate() {
      var nowDate = new Date();
      var nowYear = nowDate.getFullYear();
      var nowMonth = nowDate.getMonth();
      var nowDay = nowDate.getDate();
      var nowHour = nowDate.getHours();
      var nowMinutes = nowDate.getMinutes();
      nowMonth = nowMonth + 1;
      nowMonth = nowMonth.toString().padStart(2, "0");
      nowDay = nowDay.toString().padStart(2, "0");
      nowHour = nowHour.toString().padStart(2, "0");
      nowMinutes = nowMinutes.toString().padStart(2, "0");
      nowMinutes = Math.floor(nowMinutes / 10)
        .toString()
        .padEnd(2, "0");
      return {
        date: nowDate,
        year: nowYear,
        month: nowMonth,
        day: nowDay,
        hour: nowHour,
        minutes: nowMinutes,
      };
    },

    /** 获取结束时间 */
    getEndDate(startYear, startMonth, startDay) {
      var endDate = new Date(`${startYear}-${startMonth}-${startDay} 00:00:00`);
      endDate.setDate(endDate.getDate() + 2);
      var endDay = endDate.getDate();
      endDay = endDay.toString().padStart(2, "0");
      var endYear = endDate.getFullYear();
      var endMonth = endDate.getMonth();
      endMonth = endMonth + 1;
      endMonth = endMonth.toString().padStart(2, "0");
      return {
        date: endDate,
        year: endYear,
        month: endMonth,
        day: endDay,
      };
    },

    /** 路由跳转 */
    handleGo(path) {
      this.$router.push({ path });
      var antiCollision = this.$route.fullPath;
      if (antiCollision == '/antiCollision') {
        window.location.reload();
      }
      var warn = this.$route.fullPath;
      if (warn == "/warn") {
        window.location.reload();
      }
    },

    /** 退出 */
    exit() {
      this.$utils.clearItem();
      this.$router.push({ path: "/login" });
    },

    /** 创建地图 */
    createMap() {
      let that = this;
      let bounds = L.latLngBounds(L.latLng(36.10860961910384, 120.0752805709839), L.latLng(36.00765291341696, 120.29514312744142));
      var mapOption = {
        /** 地图初始化时的缩放等级 */
        zoom: 15,
        /** 地图的最小缩放级别 */
        minZoom: 14,
        /** 地图的最大缩放级别 */
        maxZoom: 16,
        /** 地图初始化时的中心点位置 */
        center: this.mapCenter,
        zoomControl: false,
        attributionControl: false,
        maxBounds: bounds,
      };
      this.map = this.$M.createMap("map-container", mapOption);
      this.$M.createTileLayer(this.map, this.OSMUrl, {});
      L.imageOverlay('./images/imageOverlay_03.png', L.latLngBounds([[36.055, 120.215], [36.038, 120.258]]), {
        opacity: 0.7,
        interactive: true
      }).addTo(this.map);

      L.imageOverlay('./images/imageOverlay_04.png', L.latLngBounds([[36.078, 120.213], [36.062, 120.230]]), {
        opacity: 0.7,
        interactive: true
      }).addTo(this.map);
      this.getPoint();
      this.getWaterData(
        this.nowYear,
        this.nowMonth,
        this.nowDay,
        this.nowHour,
        this.nowMinutes
      );
    },

    /** 获取点位数据 */
    getPoint() {
      axios({
        url: this.$C.HTTP_REQUEST_URL + "/api/long_and_lat",
        method: "get",
        headers: {
          Authorization: "Bearer " + this.$utils.getItem("X-Admin-Token"),
        },
      }).then((res) => {
        let data = [];
        let pointList = this.pointList;
        let CData = Object.values(res.data.data.c);
        CData.forEach((item, index) => {
          item.id = `C${index + 1}`
        })
        data = [].concat(CData);
        if (Object.values(res.data.data.l).length > 0) {
          let lData = Object.values(res.data.data.l);
          lData.forEach((item, index) => {
            item.id = `L${index + 1}`
          })
          data = data.concat(lData);
        }
        data.forEach((item, index) => {
          pointList.push({
            id: item.id,
            index: index+1,
            latlng: [item.latitude, item.longitude],
            popuptxt: `
            <div class="item">
              <img style="transform: rotate(250deg); -webkit-transform:rotate(250deg);" src="/images/icon_direction.png" />
              <span  class="text">${item.id}</span>
            </div>
            <div class="item">
              <span class="latlng">N</span>
              <span class="text">${utils.ToDegrees(item.latitude)}</span>
            </div>
            <div class="item">
              <span class="latlng">E</span>
              <span class="text">${utils.ToDegrees(item.longitude)}</span>
            </div>
          `,
            velocity: "",
            direction: "",
          });
        })
        this.pointList = pointList;
        /** 创建标记点 */
        this.createMarker();
      });
    },

    /** 获取图表数据 */
    getWarnShip() {
      this.flowto = [];
      this.speed = [];
      this.cdate = [];
      let url = '';
      let params = {
        start_cdate: `${this.startYear}-${this.startMonth}-${this.startDay} ${this.startHour}:00:00`,
        end_cdate: `${this.endYear}-${this.endMonth}-${this.endDay} 23:59:00`,
      }
      if(this.pid == 9) {
        url = this.$C.HTTP_REQUEST_URL + "/api/warnLong/list";
        params.cell = this.cell;
      }else {
        url = this.$C.HTTP_REQUEST_URL + "/api/warnShip/list";
        params.pid = this.pid;
      }
      axios({
        url: url,
        method: "get",
        headers: {
          Authorization: "Bearer " + this.$utils.getItem("X-Admin-Token"),
        },
        params: params,
      }).then((res) => {
        let data = res.data.data;
        if (data.length == 0) return;
        let flowto = [];
        let speed = [];
        let cdate = [];
        data.forEach((item) => {
          flowto.push(item.flowto);
          speed.push(item.speed);
          cdate.push(item.cdate);
        });
        this.flowto = flowto;
        this.speed = speed;
        this.cdate = cdate;
        this.open();
      });
    },

    /** 创建标记点 */
    createMarker() {
      let that = this;
      /** 点位信息 */
      let pointList = this.pointList;
      /** marker标记点数组 */
      let markerList = this.markerList;
      pointList.forEach((element, i) => {
        markerList.push(
          this.$M
            .createMakerByLatlng(element.latlng, {
              icon: this.$M.createDivIcon({
                html: `<div class="custom-icon">
              <img src="/images/Ellipse1085.png" />
              <p>${element.id}</p>
              </div>`,
                popupAnchor: [2, -6],
              }),
            })
            .on("click", function (e) {
              /** 移除之前用bindPopup绑定的弹出窗口。 */
              this.unbindPopup();
              /**
               * 将弹出窗口绑定到传入的图层 content 并设置必要的事件侦听器。如果一个Function被传递，它将接收图层作为第一个参数，并应返回 String 或 HTMLElement。
               * 打开绑定的弹出窗口
               */
              that.pid = element.index;
              that.id = element.id;
              that.getWarnShip();
              this.bindPopup(that.popuphtml(element.popuptxt)).openPopup();
              that.map.setView(that.pointList[i].latlng);
            })
        );
      });

      /** LayerGroup 图层组 */
      var layerGroup = this.$M
        .createLayerGroup(this.map, markerList)
        .on("click", function (e) {
          // console.log(e);
        });
      layerGroup.eachLayer(function (layer) {
        // console.log(layer)
      });
    },

    popuphtml(popuptxt) {
      return this.$M
        .createPopup({
          closeButton: false,
          chartButton: true,
          className: "custom-popup",
        })
        .setContent(popuptxt);
    },

    /** 打开点位流速流向图表 */
    open() {
      if (this.flowto.length == 0 || this.speed.length == 0 || this.cdate == 0){
        return;
      }
      this.myChart = echarts.init(document.getElementById("main"));
      // 旋转的度数
      var symbolRotateNum = this.flowto;
      // 数值
      var valueNum = this.speed;
      var data = echarts.util.map(valueNum, function (item, index) {
        return {
          value: valueNum[index],
          symbolRotate: -parseInt(symbolRotateNum[index]),
        };
      });

      var option = {
        dataZoom: {
          id: 'dataZoomX',
            type: 'slider',
            xAxisIndex: [0],
            filterMode: 'filter'
        },
        title: {
          text: `${this.id}${this.$t('chartTitle')}`,
        },
        legend: {
          data: [`${this.$t('velocity')}`],
        },
        tooltip: {
          trigger: "axis",
          formatter: (params, ticket, callback) => {
            let axisValueLabel = params[0].axisValueLabel
            let dataIndex = params[0].dataIndex;
            let src = `${this.$t('velocity')}：` + Number(params[0].value).toFixed(2) + 'm/s<br />';
            src += `${this.$t('direction')}：` + Math.trunc(symbolRotateNum[dataIndex]) + '° ' + this.windDirectionSwitch(Number(symbolRotateNum[dataIndex])) + '';
            return src;
          },
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        xAxis: {
          type: "category",
          data: this.cdate,
          boundaryGap: false,
          axisTick: {
            show: false,
          },
          axisLabel: {
            // 坐标轴刻度标签的显示间隔，在类目轴中有效。
            interval: 143,
            showMinLabel: true,
            showMaxLabel: true,
          },
          axisLine: {
            lineStyle: {
              opacity: 0,
            },
          },
        },
        yAxis: {
          type: "value",
          name: `${this.$t('velocity')} m/s`,
          nameTextStyle: {
            color: "#8c8c92"
          },
          axisLabel: {
            formatter: '{value}',
            textStyle: {
                color: '#8c8c92', //坐标值得具体的颜色
            }
          },
          axisLine: {
            show: true, // Y轴线
            lineStyle: {
              type: 'solid',
              color: 'rgb(224, 230, 241)'
            }  
          },
        },
        series: [
          {
            type: "line",
            name: `${this.$t('velocity')}`,
            data: data,
            symbol: "arrow",
            itemStyle: {
              color: "rgb(105, 86, 229)",
            },
            lineStyle: {
              shadowColor: "rgba(105, 86, 229, 1)",
              shadowBlur: 16,
              shadowOffsetY: 6,
              opacity: 0.9,
            },
            symbolSize: 16,
            smooth: true,
          },
        ],
      };
      this.myChart.setOption(option);
      this.myChart.dispatchAction({
        type: "showTip",
        seriesIndex: 0,
        dataIndex: 2, // 显示第几个数据
      });
    },

    /** 点击点位信息列表 */
    bindPointClick(index) {
      let marker = this.markerList[index];
      marker
        .unbindPopup()
        .bindPopup(this.popuphtml(this.pointList[index].popuptxt))
        .openPopup();
      this.map.setView(this.pointList[index].latlng);
    },

    /** 改变层级 */
    changeLevel(level) {
      if (this.level == level) return;
      this.level = level;
      this.isPlay = false;
      clearInterval(this.timer);
      clearInterval(this.innerTimer);
      this.getWaterData(
        this.oldYear,
        this.oldMonth,
        this.oldDay,
        this.oldHour,
        this.oldMinutes
      );
    },

    /** 获取潮流数据 */
    getWaterData(nowYear, nowMonth, nowDay, nowHour, nowMinutes) {
      // let url = `http://10.205.51.106/data/${nowYear}/${nowMonth}/${this.level}/${nowYear}-${nowMonth}-${nowDay} ${nowHour}_${nowMinutes}_00.json`;
      let url = `http://yubao.huaxingocean.com/data/${nowYear}/${nowMonth}/${this.level}/${nowYear}-${nowMonth}-${nowDay} ${nowHour}:${nowMinutes}:00.json`;
      this.nowTime = `${nowYear}-${nowMonth}-${nowDay} ${nowHour}:${nowMinutes}`;
      this.oldYear = nowYear;
      this.oldMonth = nowMonth;
      this.oldDay = nowDay;
      this.oldHour = nowHour;
      this.oldMinutes = nowMinutes;
      var now = new Date(
        `${nowYear}-${nowMonth}-${nowDay} ${nowHour}:${nowMinutes}:00`
      );
      now.setMinutes(now.getMinutes() + 10);
      var year = now.getFullYear();
      var month = now.getMonth();
      var day = now.getDate();
      var hour = now.getHours();
      var minutes = now.getMinutes();
      month = month + 1;
      month = month.toString().padStart(2, "0");
      day = day.toString().padStart(2, "0");
      hour = hour.toString().padStart(2, "0");
      minutes = minutes.toString().padStart(2, "0");
      minutes = Math.floor(minutes / 10)
        .toString()
        .padEnd(2, "0");
      this.nowYear = year;
      this.nowMonth = month;
      this.nowDay = day;
      this.nowHour = hour;
      this.nowMinutes = minutes;
      
      this.$axios.get(url).then((res) => {
        if (this.layer) this.layer.remove(this.map);
        if (!Array.isArray(res.data)) return;
        this.layer = this.$M.createLayer(res.data, this.map);
      });
    },

    changeDate(e) {
      var now = new Date(e);
      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      var day = now.getDate();
      month = month.toString().padStart(2, "0");
      day = day.toString().padStart(2, "0");
      this.startYear = year;
      this.startMonth = month;
      this.startDay = day;
      this.startHour = "00";
      var endDate = this.getEndDate(
        this.startYear,
        this.startMonth,
        this.startDay
      );
      this.endYear = endDate.year;
      this.endMonth = endDate.month;
      this.endDay = endDate.day;
      clearInterval(this.timer);
      clearInterval(this.innerTimer);
      this.isPlay = false;
      this.sliderNo = 0;
      this.setSliderDate();
      this.play();
    },

    changeSlider(e) {
      this.sliderNo = e;
      clearInterval(this.timer);
      clearInterval(this.innerTimer);
      this.isPlay = false;
      this.play();
    },

    play() {
      if (this.isPlay) {
        this.isPlay = false;
        clearInterval(this.timer);
        clearInterval(this.innerTimer);
      } else {
        this.isPlay = true;
        let startDate = this.getStartDate(
          `${this.startYear}-${this.startMonth}-${this.startDay} ${this.startHour}:${this.startMinutes}:00`
        );
        startDate.date.setHours(startDate.date.getHours() + this.sliderNo);
        var year = startDate.date.getFullYear();
        var month = startDate.date.getMonth() + 1;
        var day = startDate.date.getDate();
        var hour = startDate.date.getHours();
        month = month.toString().padStart(2, "0");
        day = day.toString().padStart(2, "0");
        hour = hour.toString().padStart(2, "0");
        this.nowYear = year;
        this.nowMonth = month;
        this.nowDay = day;
        this.nowHour = hour;

        let max = 71;
        let i = this.sliderNo;
        this.getData();
        this.timer = setInterval(() => {
          if (i >= max) {
            clearInterval(this.timer);
            return;
          }
          i++;
          this.sliderNo = i;
          this.getData();
        }, 60000);
      }
    },

    getData() {
      let i = 0;
      this.getWaterData(
        this.nowYear,
        this.nowMonth,
        this.nowDay,
        this.nowHour,
        `00`
      );
      this.innerTimer = setInterval(() => {
        if (i >= 5) {
          clearInterval(this.innerTimer);
          return;
        }
        i++;
        this.getWaterData(
          this.nowYear,
          this.nowMonth,
          this.nowDay,
          this.nowHour,
          `${i}0`
        );
      }, 10000);
    },

    windDirectionSwitch(degrees) {
      let index = 0;
      if (348.75 <= degrees && degrees <= 360) {
        index = 0;
      } else if (0 <= degrees && degrees <= 11.25) {
        index = 0;
      } else if (11.25 < degrees && degrees <= 33.75) {
        index = 1;
      } else if (33.75 < degrees && degrees <= 56.25) {
        index = 2;
      } else if (56.25 < degrees && degrees <= 78.75) {
        index = 3;
      } else if (78.75 < degrees && degrees <= 101.25) {
        index = 4;
      } else if (101.25 < degrees && degrees <= 123.75) {
        index = 5;
      } else if (123.75 < degrees && degrees <= 146.25) {
        index = 6;
      } else if (146.25 < degrees && degrees <= 168.75) {
        index = 7;
      } else if (168.75 < degrees && degrees <= 191.25) {
        index = 8;
      } else if (191.25 < degrees && degrees <= 213.75) {
        index = 9;
      } else if (213.75 < degrees && degrees <= 236.25) {
        index = 10;
      } else if (236.25 < degrees && degrees <= 258.75) {
        index = 11;
      } else if (258.75 < degrees && degrees <= 281.25) {
        index = 12;
      } else if (281.25 < degrees && degrees <= 303.75) {
        index = 13;
      } else if (303.75 < degrees && degrees <= 326.25) {
        index = 14;
      } else if (326.25 < degrees && degrees < 348.75) {
        index = 15;
      }
      return this.$t('directArr', {index});
    },
    changeLanguage() {
      let language = Cookie.get("language"),
      type;
      if (language == 'en') type = 'zh';
      else if (language == 'zh') type = 'en';
      else type = 'en';
      Cookie.set('language', type) 
      this.$i18n.locale = type 
      this.marks = {
        0: "0",
        6: `06${this.$t('hour')}`,
        12: `12${this.$t('hour')}`,
        18: `18${this.$t('hour')}`,
        24: "0",
        30: `06${this.$t('hour')}`,
        36: `12${this.$t('hour')}`,
        42: `18${this.$t('hour')}`,
        48: "0",
        54: `06${this.$t('hour')}`,
        60: `12${this.$t('hour')}`,
        66: `18${this.$t('hour')}`,
        71: `23${this.$t('hour')}`,
      }
      this.setSliderDate()
      // this.$message.success('切换多语言成功')
    }
  },
};
</script>

<style lang="scss" scoped>
.header_l,
.header_r {
  z-index: 2000;
  position: fixed;
  top: 30px;
  left: 30px;
  height: 52px;
  background-color: #fff;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    padding-left: 18px;

    img {
      width: 226px;
    }
  }

  .tit {
    font-size: 24px;
    margin: 0 10px;
    font-family: cursive;
    font-weight: bold;
  }

  .nav {
    display: flex;
    align-items: center;
    justify-content: center;

    .item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 52px;
      border-radius: 30px;
      font-size: 16px;
      color: #2a3537;
      cursor: pointer;
      min-width: 150px;
      padding: 0 6px;
      box-sizing: border-box;

      &.active {
        background-color: #3396de;
        color: #fff;
      }
    }
  }
}
.header_l {
  left: 30px;
}
.header_r {
  left: 965px;
  .tit {
    margin-left: 16px;
  }
}
.map-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.sidebar {
  z-index: 1111;
  position: fixed;
  right: 40px;
  top: 100px;

  .marker-area {
    width: 240px;
    padding: 10px 20px;
    background: rgba(94, 175, 234, 0.79);
    border-radius: 0 0px 0px 16px;

    .item {
      height: 40px;
      background: rgba(37, 113, 130, 0.58);
      border-radius: 7px;
      font-size: 16px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
      padding: 0 15px;
      cursor: pointer;

      .id {
        width: 25px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        border-radius: 4px;
      }

      &:nth-child(odd) .id {
        background-color: #d72690;
      }

      &:nth-child(even) .id {
        background-color: #3b28b9;
      }

      .velocity {
      }

      .direction {
      }
    }
  }
}

.tide3 {
  z-index: 2000;
  position: absolute;
  right: 30px;
  bottom: 530px;
  width: 140px;
  height: 38px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.6);
  font-size: 18px;
  color: #505d60;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    flex: 1;
    display: block;
    text-align: center;
  }

  .icon {
    width: 38px;
    height: 38px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
  }
}

.tide {
  z-index: 2000;
  position: absolute;
  right: 30px;
  bottom: 530px;
  width: 90px;
  height: 30px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  color: #505d60;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    flex: 1;
    display: block;
    text-align: center;
  }

  .icon {
    width: 30px;
    height: 30px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;

    img {
      width: 20px;
    }
  }
}


.level {
  z-index: 2000;
  position: absolute;
  right: 30px;
  bottom: 176px;
  width: 26px;
  background-color: #fff;
  border-radius: 18px;
  overflow: hidden;

  .item {
    font-size: 14px;
    color: #6f94bc;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    text-align: center;
    box-sizing: border-box;
    width: 26px;
    writing-mode: tb;
    &.active {
      background-color: #6f94bc;
      color: #fff;
    }

    &.no-radius {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: none;
    }
  }
}

.info-icon {
  z-index: 2000;
  position: absolute;
  right: 30px;
  bottom: 120px;
}

.login-area {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 2000;
  display: flex;
  flex-direction: column;

  &:hover {
    .dropdown {
      display: inline-flex;
    }
  }

  .dropdown {
    display: none;
    justify-content: flex-start;
    flex-direction: column;
    margin: 10px auto 0;
    border-radius: 6px;
    padding: 6px;
    box-shadow: 0 3px 14px 2px rgba(0, 0, 0, .05), 0 8px 10px 1px rgba(0, 0, 0, 6%), 0 5px 5px -3px rgba(0, 0, 0, 10%);
    background-color: #fff;
    font-size: 14px;
    color: #2a3537;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -20px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom: 10px solid #fff;
    }

    .dropdown-item {
      padding: 4px 10px;
      text-align: center;
      cursor: pointer;
      &:hover {
        background-color: #f3f3f3;
        color: #3396de;
      }
    }
    .dropdown-horizontal {
      height: 0.5px;
      margin: 2px 0;
      background-color: #ccc;
    }
  }

  .user {
    background: #fff;
    padding: 10px 16px;
    border-radius: 22px;
    color: #2a3537;
  }
}

.bottom {
  z-index: 2002;
  position: absolute;
  bottom: 70px;
  left: 10px;
  right: 10px;
  // height: 140px;
  display: flex;
  align-items: center;
  padding-right: 20px;

  .more {
    position: absolute;
    bottom: -34px;
    background: #fff;
    border-radius: 20px;
    padding: 4px 15px;
    font-size: 14px;
    cursor: pointer;
    color: #6f94bc;
    left: 80px;
    z-index: 2004;
  }

  .play-back-action {
    display: flex;
    align-items: center;
    margin-right: 40px;

    .play-back-action-btn {
      background: linear-gradient(149.93deg, #3396de 14.36%, #3394bc 85.25%);
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-right: 20px;
      box-shadow: 0px 0px 10px 5px #3396de;
      cursor: pointer;
    }

    .play-back-action-calendar {
    }
  }

  .play-back-progress {
    width: 90%;
    margin: 0 auto;
    position: relative;
    .play-back-time {
      position: absolute;
      top: -30px;
      right: 0px;
    }

    .play-back-progressbar {
      .progressbar-percentage {
      }
    }
  }
}

.iconfont {
  font-size: 22px;
  color: #fff;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 160px;
}

.el-input--suffix .el-input__inner {
  padding-right: 10px;
}

.tag-content {
  z-index: 2000;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 14px;
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  .tag-logo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .tag-title {
    font-size: 20px;
    color: #3666b8;
  }
}
</style>

<style lang="scss">

.custom-popup {
  left: 0 !important;
  bottom: 90px !important;
  margin-bottom: 0 !important;

  .leaflet-popup-content-wrapper {
    margin: 0;
    box-shadow: none;
    background-color: rgba(111, 148, 188, 0.83);
    position: relative;
    border-radius: 0;
    padding: 0;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -90px;
      width: 4px;
      height: 90px;
      background-color: rgba(111, 148, 188, 0.83);
    }

    // &::after {
    //   content: "";
    //   position: absolute;
    //   background: url();
    // }
    .leaflet-popup-content {
      margin: 0;
      // width: auto !important;
      padding: 6px 40px 6px 10px;
      height: 73px;
      line-height: initial;
      box-sizing: border-box;

      .item {
        display: flex;
        align-items: center;

        img {
          margin-right: 7px;
        }

        .latlng {
          font-size: 16px;
          color: #f2898b;
          margin-right: 9px;
        }
      }

      .text {
        font-size: 16px;
        color: #fff;
        margin: 0;
      }
    }
  }

  .leaflet-popup-tip-container {
    display: none;

    .leaflet-popup-tip {
    }
  }

  .leaflet-popup-close-button {
  }

  .leaflet-popup-chart-buttons {
    width: 73px;
    height: 73px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    right: -40px;
    top: 0;
    cursor: pointer;

    .chart-buttons-icon {
      width: 22px;
      height: 22px;
      border: 4px solid #3396de;
      transform: rotate(45deg);
      border-bottom: none;
      border-left: none;
      margin: auto;
      top: 0;
      bottom: 0;
      right: 28px;
      position: absolute;
    }
  }

  .leaflet-popup-chart-box {
    display: none;
    position: absolute;
    background-color: #fff;
    width: 720px;
    height: 430px;
    top: 0;
    left: -730px;
    padding: 20px 10px;
    box-sizing: border-box;
  }
}

.el-slider__marks-text {
  color: #333 !important;
  width: max-content;
}

.leaflet-div-icon {
  background: none !important;
  border: none !important;

  .custom-icon {
    display: flex;
    flex-direction: column;
    min-width: 20px;

    p {
      color: #ff4242;
      text-align: center;
      font-size: 14px;
    }
  }
}
.cell-box {
    font-size: 14px;
    z-index: 2000;
    position: absolute;
    right: 20px;
    bottom: 40px;
    width: 26px;
    background-color: #fff;
    overflow: hidden;
  .cell {
      font-size: 14px;
      color: #6f94bc;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-align: center;
      box-sizing: border-box;
      border: 1px solid #ccc;
      margin-bottom: 2px;

      &.active {
        background: #6f94bc;
        color: #fff;
      }
      &.no-radius {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: none;
    }
  }
}
</style>
