L.CustomPopup = L.Popup.extend({
  _initLayout: function() {
    var prefix = 'leaflet-popup',
      container = this._container = L.DomUtil.create('div',
      prefix + ' ' + (this.options.className || '') +
      ' leaflet-zoom-animated');
    // var wrapper = container;
    var wrapper = this._wrapper = L.DomUtil.create('div', prefix + '-content-wrapper', container);
    this._contentNode = L.DomUtil.create('div', prefix + '-content', wrapper);
    // this._contentNode = L.DomUtil.create("div", prefix + "-content", wrapper);

    L.DomEvent.disableClickPropagation(wrapper)
      .disableScrollPropagation(this._contentNode)
      .on(wrapper, "contextmenu", L.DomEvent.stopPropagation);
    
    if (this.options.closeButton) {
      var closeButton = this._closeButton = L.DomUtil.create('a', prefix + '-close-button', container);
      closeButton.innerHTML = '&#215;';
      L.DomEvent.on(closeButton, 'click', this.close, this);
    }

    if (this.options.chartButton) {
      var chartButton = this._chartButton = L.DomUtil.create('div', prefix + '-chart-buttons', wrapper);
      chartButton.id = "btnCon";
      chartButton.innerHTML = `<div class="chart-buttons-icon"></div>`;
      var chartBox = this._chartBox = L.DomUtil.create("div", prefix + "-chart-box", wrapper);
      chartBox.innerHTML = `<div class="cell-box">
      <div class="cell " data-cell="1">1</div>
      <div class="cell" data-cell="2">2</div>
      <div class="cell" data-cell="3">3</div>
      <div class="cell" data-cell="4">4</div>
      <div class="cell" data-cell="5">5</div>
      <div class="cell" data-cell="6">6</div>
      <div class="cell" data-cell="7">7</div>
      <div class="cell" data-cell="8">8</div>
      </div><div id="main" style="width: 700px;height: 400px;"></div>`;
      L.DomEvent.on(chartButton, 'click', this._onChartButtonClick, this)
    }

  },

  _onChartButtonClick: function(e) {
    this._chartBox.style.display = "block";
  },

  _onCancelButtonClick: function (e) {
    L.DomUtil.remove(this._editScreen);
    this._contentNode.style.display = "block";
    this._userActionButtons.style.display = "flex";

    this.update();
    L.DomEvent.stop(e);
 },

});

L.customPopup = function (options, source) {
  return new L.CustomPopup(options, source);
};

L.Layer.include({
  bindCustomPopup: function (content, options) {
    if (content instanceof L.Popup) {
      L.setOptions(content, options);
      this._popup = content;
      content._source = this;
    } else {
      if (!this._popup || options) {
        this._popup = new L.CustomPopup(options, this);
      }
      this._popup.setContent(content);
    }

    if (!this._popupHandlersAdded) {
      this.on({
        click: this._openPopup,
        remove: this.closePopup,
        move: this._movePopup,
      });
      this._popupHandlersAdded = true;
    }

    return this;
  },
});