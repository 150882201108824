<template>
  <div class="map-container" id="map-container"></div>
</template>

<script>
export default {
  props: {
    /** 地图初始化时的中心点位置 */
    mapCenter: {
      type: Array,
      default: () => [36.059368782721876, 120.2372932434082]
    }
  },
  data() {
    return {
      map: null,
      // OSMUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      // OSMUrl: "http://yztmap.qdport.com:6080/arcgis/rest/services/qdportsea/MapServer/{z}/{x}/{y}.jpg",
      OSMUrl: "/newtask/{z}/{x}/{y}.png",
    };
  },
  mounted() {
    /** 创建地图 */
    this.createMap();
  },
  methods: {
    /** 创建地图 */
    createMap() {
      var mapOption = {
        /** 地图初始化时的缩放等级 */
        zoom: 15,
        /** 地图的最小缩放级别 */
        minZoom: 15,
        /** 地图的最大缩放级别 */
        maxZoom: 15,
        /** 地图初始化时的中心点位置 */
        center: this.mapCenter,
        zoomControl: false,
        attributionControl: false,
        dragging: false
      }
      this.map = this.$M.createMap("map-container", mapOption);
      this.$M.createTileLayer(this.map, this.OSMUrl, {});
    }
  }
};
</script>

<style lang="scss" scoped>
.map-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
</style>
