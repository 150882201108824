<template>
  <div>
    <map-con style="filter: blur(3px)" />
    <div class="header_l">
      <div class="logo">
        <img src="/images/logo.png" alt="logo" />
      </div>
      <div class="tit">黄岛油港区</div>
      <div class="nav">
        <div class="item" @click="handleGo('/forecast')">潮流预报</div>
        <div class="item" @click="handleGo('/antiCollision')">监控预警</div>
        <div class="item active">90泊位预报</div>
      </div>
    </div>
    <div class="header_r">
      <div class="tit">董家口港区</div>
      <div class="nav">
        <div class="item" @click="handleGo('/d12')">D12泊位预报</div>
      </div>
    </div>
    <div class="ctitle">气象水文预报信息数据来源于《船舶作业条件预报预警系统》</div>

    <div class="container">
      <div class="row">
        <div class="col">
          <div class="title">72小时波浪数据预报</div>
          <div class="chart" style="width: 370px; display: inline-block;" id="container_wave72"></div>
          <div class="compass">
            <div class="compass-tit">波向</div>
            <div class="compass-icon" style="display: inline-block;">
              <img class="pointer-icon" src="/images/pointer-icon.png" :style="{transform: 'rotateZ(' + dirText + 'deg)'}">
              <span class="compass-dir">{{ dirText }}°</span>
            </div>
            <div class="compass-time">{{ $utils.windDirection(dirText) }}</div>
          </div>
        </div>
        <div class="col">
          <div class="title">7天波浪数据预报</div>
          <div class="compass">
            <div class="compass-tit">波向</div>
            <div class="compass-icon" style="display: inline-block;">
              <img class="pointer-icon" src="/images/pointer-icon.png" :style="{transform: 'rotateZ(' + dirText7 + 'deg)'}">
              <span class="compass-dir">{{ dirText7 }}°</span>
            </div>
            <div class="compass-time">{{ $utils.windDirection(dirText7) }}</div>
          </div>
          <div class="chart" style="width: 370px; display: inline-block;" id="container_wave7"></div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="title">72小时涌浪数据预报</div>
          <div class="chart" id="container_waveYl72"></div>
        </div>
        <div class="col">
          <div class="title">7天涌浪数据预报</div>
          <div class="chart" id="container_waveYl7"></div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="title">72小时风力数据预报</div>
          <div class="chart" id="container_wind72"></div>
        </div>
        <div class="col">
          <div class="title">7天风力数据预报</div>
          <div class="chart" id="container_wind7"></div>
        </div>
      </div>
      <!-- <div class="row" style="justify-content: space-around;"> -->
      <div class="row">
        <div class="col">
          <div class="title">72小时能量预报预警</div>
          <div class="chart" id="container_energy72"></div>
        </div>
        <div class="clear"></div>
        
      </div>
    </div>
    
    <div class="login-area">
      <div class="user">
        用户：{{ account
        }}<i class="el-icon-arrow-down" style="color: #3396de"></i>
      </div>
      <div class="dropdown">
        <div class="dropdown-item" @click="handleGo('/reset')">修改密码</div>
        <div class="dropdown-horizontal"></div>
        <div class="dropdown-item" @click="exit">退出登录</div>
      </div>
    </div>
  </div>
</template>

<script>
var colorArr = {
	blue : "#186dfd",
	green : "#50fde5",
	red : "#fd4658",
	yellow : "#f56538",
	orange : "#fcaa52",
	purple : "#cb69f4",
	glass : "#96bdb8",
	dark : "#102334"
};
Date.prototype.format = function (format) {
  var date = {
      "M+": this.getMonth() + 1,
      "d+": this.getDate(),
      "h+": this.getHours(),
      "m+": this.getMinutes(),
      "s+": this.getSeconds(),
      "q+": Math.floor((this.getMonth() + 3) / 3),
      "S+": this.getMilliseconds()
  };
  if (/(y+)/i.test(format)) {
      format = format.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (var k in date) {
      if (new RegExp("(" + k + ")").test(format)) {
          format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? date[k] : ("00" + date[k]).substr(("" + date[k]).length));
      }
  }
  return format;
}
import MapCon from "@/components/map";
import * as echarts from "echarts";
import axios from "axios";
export default {
  name: "D90",
  components: {
    MapCon
  },
  data() {
    return {
      account: this.$utils.getItem("account"),
      dirList: [],
      dirText: "0",
      dir7List: [],
      dirText7: "0",
      baseUrl: "http://10.205.51.106:8088"
      // baseUrl: "http://cws.huaxingocean.com"
    }
  },
  mounted() {
    // WndSpeed风速      WndDir风向
    // H4 4%波高        H10  
    // 波浪 涌浪 风力 波浪能量 波浪实测
    this.getWaveForecast();
  },
  methods: {
    getWaveForecast() {
      axios({
        url: this.baseUrl + "/api/warnBerth/chart_list",
        method: "get",
      }).then(res => {
        var data = res.data.data;
        this.dir = data.hour72.dir;
        this.initWave72(data.hour72);
        this.initWave7(data.day7);
        this.initWaveYl72(data.hour72);
        this.initWaveYl7(data.day7);
        this.initWind72(data.hour72);
        this.initWind7(data.day7);
        this.initEnergy72(data.hour72);
      })
    },
    // 72小时波浪
    initWave72(data) {
      var dayArr = [];
      var dom_wave72 = document.getElementById("container_wave72");
      var myChart_wave = echarts.init(dom_wave72);
      var option_wave;
      // 有效波高
      var HsArr = [];
      // 最大波高
      var HmaxeArr = [];
      // 平均波周期
      var TMArr = [];
      // 4%波高
      var h4Arr = [];
      for (var i = 0; i < data.length; i++) {
        HsArr[i] = data[i].hs;
        HmaxeArr[i] = data[i].hmaxe;
        TMArr[i] = data[i].tm;
        h4Arr[i] = data[i].hfour;
        this.dirList[i] = data[i].dir;
        var d = new Date(data[i].cdate).format("hh:mm");
        if (d == "00:00") {
          d = new Date(data[i].cdate).format("MM-dd");
        }
        dayArr[i] = d;
      }
      var waveData = data;
      //获取当前时间，计算X轴开始点
      var d1 = new Date().format("hh");
      var startX = (d1 / 72) * 100;
      option_wave = {
        tooltip: {
          trigger: 'axis',
          formatter: (tipData)=> {
            this.dirText = Math.trunc(waveData[tipData[0].dataIndex].dir);
            var ddd = new Date(waveData[tipData[0].dataIndex].cdate).format('MM-dd hh:mm');
            var res = ddd
                + "<br></waveData.length;i++){>" + "有效波高" + waveData[tipData[0].dataIndex].hs
                + "<br/>" + "4%波高" + waveData[tipData[0].dataIndex].hfour
                + "<br/>" + "最大波高" + waveData[tipData[0].dataIndex].hmaxe
                + "<br/>" + "平均波周期" + waveData[tipData[0].dataIndex].tm;
            return res;
          }
        },
        legend: {
          data: ['有效波高', '4%波高', '最大波高', '平均波周期'],
          textStyle: {
            fontSize: 12,
          },
          selected: {
            '有效波高': false,
            '4%波高': false,
            '最大波高': true,
            '平均波周期': true
          }
        },
        grid: {
          left: '3%',
          right: '1%',
          bottom: '1%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: dayArr,
            splitLine: {
                show: false,
            },
            axisLine: {
              lineStyle: {
                width: 1,
              }
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '              波高：m',
            axisLabel: {
              textStyle: {
                fontSize: 12,
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                type: 'solid',
              }  
            },
          },
          {
            type: 'value',
            name: '',
            splitLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
              }
            }
          },
          {
            type: 'value',
            name: '',
            splitLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
              }
            }
          },
          {
            type: 'value',
            name: '周期：s  ',
            splitLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                type: 'solid',
              }  
            },
          }
        ],
        dataZoom: [{
          show: false,
          start: startX,
          end: 100
        }, {
          type: 'inside'
        }],
        series: [
          {
            name: '有效波高',
            type: 'line',
            yAxisIndex: 0,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: HsArr
          },
          {
            name: '4%波高',
            type: 'line',
            yAxisIndex: 0,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: h4Arr
          },
          {
            name: '最大波高',
            type: 'line',
            yAxisIndex: 0,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: HmaxeArr
          },
          {
            name: '平均波周期',
            type: 'line',
            yAxisIndex: 1,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: TMArr
          }
        ]
      };
      if (option_wave && typeof option_wave === "object") {
        myChart_wave.setOption(option_wave, true);
      }
      myChart_wave.on("mouseover", 'series',(params)=> {
        // this.dirText = Math.trunc(this.dirList[params.dataIndex]);
      })
    },
    // 7天波浪数据预报
    initWave7(data) {
      var dayArr = [];
      var dom_wave7 = document.getElementById("container_wave7");
      var myChart_wave7 = echarts.init(dom_wave7);
      var option_wave7;
      // 有效波高
      var HsArr = [];
      // 最大波高
      var HmaxeArr = [];
      // 平均波周期
      var TMArr = [];
      // 4%波高
      var h4Arr = [];
      var waveData7 = data;
      for (var i = 0; i < waveData7.length; i++) {
        HsArr[i] = waveData7[i].hs;
        HmaxeArr[i] = waveData7[i].hmaxe;
        TMArr[i] = waveData7[i].tm;
        h4Arr[i] = waveData7[i].hfour;
        this.dir7List[i] = data[i].dir;
        var d = new Date(waveData7[i].cdate).format('hh:mm');
        if (d == "00:00") {
          d = new Date(waveData7[i].cdate).format('MM-dd');
        }
        dayArr[i] = d;
      }
      option_wave7 = {
        tooltip: {
          trigger: 'axis',
          formatter: (tipData) => {
            this.dirText7 = Math.trunc(waveData7[tipData[0].dataIndex].dir);
            var ddd = new Date(waveData7[tipData[0].dataIndex].cdate).format('MM-dd hh:mm');
            var res = ddd
                + "<br></waveData7.length;i++){>" + "有效波高" + waveData7[tipData[0].dataIndex].hs
                + "<br/>" + "4%波高" + waveData7[tipData[0].dataIndex].hfour
                + "<br/>" + "最大波高" + waveData7[tipData[0].dataIndex].hmaxe
                + "<br/>" + "平均波周期" + waveData7[tipData[0].dataIndex].tm;
            return res;
          }
        },
        legend: {
          data: ['有效波高', '4%波高', '最大波高', '平均波周期'],
          textStyle: {
            fontSize: 12,
          },
          selected: {
            '有效波高': false,
            '4%波高': false,
            '最大波高': true,
            '平均波周期': true
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: dayArr,
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 1,
              }
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '      波高m',
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: "rgb(110,112,121)"
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgb(144,147,154)'
              }  
            },
          },
          {
            type: 'value',
            name: '',
            splitLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
              }
            }
          },
          {
            type: 'value',
            name: '',
            splitLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
              }
            }
          },
          {
            type: 'value',
            name: '周期：s',
            splitLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: "rgb(110,112,121)"
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgb(144,147,154)'
              }  
            },
          }
        ],
        dataZoom: [{
          show: false,
          start: 0,
          end: 100
        }, {
          type: 'inside'
        }],
        series: [
          {
            name: '有效波高',
            type: 'line',
            yAxisIndex: 0,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: HsArr
          },
          {
            name: '4%波高',
            type: 'line',
            yAxisIndex: 0,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: h4Arr
          },
          {
            name: '最大波高',
            type: 'line',
            yAxisIndex: 0,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: HmaxeArr
          },
          {
            name: '平均波周期',
            type: 'line',
            yAxisIndex: 1,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: TMArr
          }
        ]
      };
      if (option_wave7 && typeof option_wave7 === "object") {
        myChart_wave7.setOption(option_wave7, true);
      }
      myChart_wave7.on("mouseover", 'series',(params)=> {
        // this.dirText7 = Math.trunc(this.dir7List[params.dataIndex]);
      })
    },
    // 72小时涌浪数据预报
    initWaveYl72(data) {
      var dayArrWaveYl72 = new Array();
      var dom_waveYl72 = document.getElementById("container_waveYl72");
      var myChart_waveYl = echarts.init(dom_waveYl72);
      var option_waveYl;
      var waveData = data;
      var waveYxbgArr = new Array();
      var waveZqArr = new Array();
      for (var i = 0; i < waveData.length; i++) {
        waveYxbgArr[i] = waveData[i].swellhs;
        waveZqArr[i] = waveData[i].swelltm;
        var d = new Date(waveData[i].cdate).format('MM-dd hh:mm');
        dayArrWaveYl72[i] = d;
      }
      //获取当前时间，计算X轴开始点
      var d1 = new Date().format('hh');
      var startX = d1 / 72 * 100;
      option_waveYl = {
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: ['有效波高', '平均波周期'],
          textStyle: {
            fontSize: 12,
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: dayArrWaveYl72,
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 1,
              }
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '                 有效波高：m',
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: "rgb(110,112,121)"
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgb(144,147,154)'
              }  
            },
          },
          {
            type: 'value',
            name: '周期：s',
            splitLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: "rgb(110,112,121)"
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgb(144,147,154)'
              }  
            },
          }
        ],
        dataZoom: [{
            show: false,
            start: startX,
            end: 100
        }, {
            type: 'inside'
        }],
        series: [
          {
            name: '有效波高',
            type: 'line',
            yAxisIndex: 0,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: waveYxbgArr
          },
          {
            name: '平均波周期',
            type: 'line',
            yAxisIndex: 1,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: waveZqArr
          }
        ]
      };

      if (option_waveYl && typeof option_waveYl === "object") {
        myChart_waveYl.setOption(option_waveYl, true);
      }
    },
    // 7天涌浪数据预报
    initWaveYl7(data) {
      var dayArrWaveYl7 = new Array();
      var dom_waveYl7 = document.getElementById("container_waveYl7");
      var myChart_waveYl7 = echarts.init(dom_waveYl7);
      var option_waveYl7;
      var waveData = data;
      var waveYxbgArr = new Array();
      var waveZqArr = new Array();
      for (var i = 0; i < waveData.length; i++) {
        waveYxbgArr[i] = waveData[i].swellhs;
        waveZqArr[i] = waveData[i].swelltm;
        var d = new Date(waveData[i].cdate).format('MM-dd hh:mm');
        dayArrWaveYl7[i] = d;
      }
      option_waveYl7 = {
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: ['有效波高', '平均波周期'],
          textStyle: {
            fontSize: 12,
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: dayArrWaveYl7,
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 1,
              }
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '      有效波高m',
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: "rgb(110,112,121)"
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgb(144,147,154)'
              }  
            },
          },
          {
            type: 'value',
            name: '周期：s',
            splitLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: "rgb(110,112,121)"
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgb(144,147,154)'
              }  
            },
          }
        ],
        dataZoom: [{
            show: false,
            start: 0,
            end: 100
        }, {
            type: 'inside'
        }],
        series: [
          {
            name: '有效波高',
            type: 'line',
            yAxisIndex: 0,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: waveYxbgArr
          },
          {
            name: '平均波周期',
            type: 'line',
            yAxisIndex: 1,
            smooth: true,
            lineStyle: {
                width: 2
            },
            data: waveZqArr
          }
        ]
      };

      if (option_waveYl7 && typeof option_waveYl7 === "object") {
        myChart_waveYl7.setOption(option_waveYl7, true);
      }
    },
    // 72小时风力数据预报
    initWind72(data) {
      var dayArrWind72 = new Array();
      var dom_wind72 = document.getElementById("container_wind72");
      var myChart_wind72 = echarts.init(dom_wind72);
      var wind_option72;
      var windData = data;
      var windArr = new Array();
      for (var i = 0; i < windData.length; i++) {
        windArr[i] = windData[i].wndspeed;
        var d = new Date(windData[i].cdate).format('MM-dd hh:mm');
        dayArrWind72[i] = d;
      }
      //获取当前时间，计算X轴开始点
      var d1 = new Date().format('hh');
      var startX = d1 / 72 * 100;
      wind_option72 = {
        tooltip: {
          trigger: 'axis',
          formatter: function (tipData) {
            var ddd = new Date(windData[tipData[0].dataIndex].cdate).format('MM-dd hh:mm');
            var res = ddd + "<br></windData.length;i++){>风向：" + windData[tipData[0].dataIndex].wwdir + "，风速：" + windData[tipData[0].dataIndex].wndspeed + "m/s";
            return res;
          }
        },
        legend: {
          data: ['风力等级'],
          textStyle: {
            fontSize: 12,
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: dayArrWind72,
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 1,
              }
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '    单位：级',
            splitLine: {
              lineStyle: {
                width: 1,
              }
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: "rgb(110,112,121)"
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgb(144,147,154)'
              }  
            },
            axisTick: {
              show: true
            },
            splitNumber: 4
          }
        ],
        dataZoom: [{
            show: false,
            start: startX,
            end: 100
        }, {
            type: 'inside'
        }],
        series: [
          {
            name: '风力等级',
            type: 'line',
            yAxisIndex: 0,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: windArr
          }
        ]
      };
      if (wind_option72 && typeof wind_option72 === "object") {
        myChart_wind72.setOption(wind_option72, true);
      }
    },
    // 7天风力数据预报
    initWind7(data) {
      var dayArrWind7 = new Array();
      var dom_wind7 = document.getElementById("container_wind7");
      var myChart_wind7 = echarts.init(dom_wind7);
      var wind_option7;
      var windData = data;
      var windArr = new Array();
      for (var i = 0; i < windData.length; i++) {
        windArr[i] = windData[i].wndspeed;
        var d = new Date(windData[i].cdate).format('MM-dd hh:mm');
        dayArrWind7[i] = d;
      }
      wind_option7 = {
        tooltip: {
          trigger: 'axis',
          formatter: function (tipData) {
            var ddd = new Date(windData[tipData[0].dataIndex].cdate).format('MM-dd hh:mm');
            var res = ddd + "<br></windData.length;i++){>风向：" + windData[tipData[0].dataIndex].wwdir + "，风速：" + windData[tipData[0].dataIndex].wndspeed + "m/s";
            return res;
          }
        },
        legend: {
          data: ['风力等级'],
          textStyle: {
            fontSize: 12,
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: dayArrWind7,
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 1,
              }
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '    单位：级',
            splitLine: {
              lineStyle: {
                width: 1,
              }
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: "rgb(110,112,121)"
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgb(144,147,154)'
              }  
            },
            axisTick: {
              show: true
            },
            splitNumber: 4
          }
        ],
        dataZoom: [{
            show: false,
            start: 0,
            end: 100
        }, {
            type: 'inside'
        }],
        series: [
          {
            name: '风力等级',
            type: 'line',
            yAxisIndex: 0,
            smooth: true,
            lineStyle: {
              width: 2
            },
            data: windArr
          }
        ]
      };
      if (wind_option7 && typeof wind_option7 === "object") {
        myChart_wind7.setOption(wind_option7, true);
      }
    },
    // 72小时能量预报预警
    initEnergy72(data) {
      var dayArrEnergy72 = new Array();
      var energy_dom72 = document.getElementById("container_energy72");
      var myChart_energy72 = echarts.init(energy_dom72);
      var energy_option72;
      var energyData;
      energyData = data;
      var energyStateArr = new Array();
      for (var i = 0; i < energyData.length; i++) {
        energyStateArr[i] = energyData[i].cge;
        var d = new Date(energyData[i].cdate).format('MM-dd hh:mm');
        dayArrEnergy72[i] = d;
      }
      //获取当前时间，计算X轴开始点
      var d1 = new Date().format('hh');
      var startX = d1 / 72 * 100;
      energy_option72 = {
          title: {
            text: '0-4.5作业条件良好，4.6-6基本具备作业条件，6.1-25不具备作业条件，25以上逃离码头',
            textStyle: {
              color: colorArr.yellow,
              fontSize: 12,
              fontWeight: 'bold',
            },
            top: 'top',
            left: 'center'
          },
          tooltip: {
            trigger: 'axis',
            formatter: function (tipData) {
                var ddd = new Date(energyData[tipData[0].dataIndex].cdate).format('MM-dd hh:mm');
                var res = ddd
                    + "<br></energyData.length;i++){>" + "能量指数" + energyData[tipData[0].dataIndex].cge;
                return res;
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: dayArrEnergy72,
              splitLine: {
                show: false,
              },
              axisLine: {
                lineStyle: {
                  width: 1,
                }
              },
              axisLabel: {
                textStyle: {
                  fontSize: 12,
                }
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '            单位：能量指数',
              splitLine: {
                lineStyle: {
                  width: 1,
                }
              },
              axisLine: {
                show: false
              },
              axisLabel: {
                textStyle: {
                  fontSize: 12,
                  color: "rgb(110,112,121)"
                }
              },
              axisLine: {
                show: true,
                lineStyle: {
                  type: 'solid',
                  color: 'rgb(144,147,154)'
                }  
              },
              axisTick: {
                show: true
              },
              //splitNumber : 10,
              //min:0,
              //max:10
            }
          ],
          dataZoom: [{
              show: false,
              start: startX,
              end: 100
          }, {
              type: 'inside'
          }],
          color: ['#87CEFA', '#FFFF00', '#FF8C00', '#FF0000'],
          series: [
              {
                  name: '蓝色预警',
                  type: 'line',
                  animation: false,
                  areaStyle: {
                      normal: {}
                  },
                  lineStyle: {
                      normal: {
                          width: 2
                      }
                  },
                  markArea: {
                      data: [[{
                          yAxis: '0'
                      }, {
                          yAxis: '4.5'
                      }]]
                  },
              }
              , {
                  name: '黄色预警',
                  type: 'line',
                  animation: false,
                  areaStyle: {
                      normal: {}
                  },
                  lineStyle: {
                      normal: {
                          width: 2
                      }
                  },
                  markArea: {
                      data: [[{
                          yAxis: '4.6'
                      }, {
                          yAxis: '6'
                      }]]
                  }
              }
              , {
                  name: '橙色预警',
                  type: 'line',
                  animation: false,
                  areaStyle: {
                      normal: {}
                  },
                  lineStyle: {
                      normal: {
                          width: 2
                      }
                  },
                  markArea: {
                      data: [[{
                          yAxis: '6.1'
                      }, {
                          yAxis: '25'
                      }]]
                  }
              }
              , {
                  name: '红色预警',
                  type: 'line',
                  animation: false,
                  areaStyle: {
                      normal: {}
                  },
                  lineStyle: {
                      normal: {
                          width: 2
                      }
                  },
                  markArea: {
                      data: [[{
                          yAxis: '25.1'
                      }, {
                          yAxis: '40'
                      }]]
                  }
              },
              {
                  name: '故障发生概率',
                  type: 'line',
                  data: energyStateArr,
                  itemStyle: {
                      normal: {
                          lineStyle: {
                              color: '#00FF00'
                          }
                      }
                  },
              },
          ],
      };
      if (energy_option72 && typeof energy_option72 === "object") {
        myChart_energy72.setOption(energy_option72, true);
      }
    },
    handleGo(path) {
      this.$router.push({ path });
      var antiCollision = this.$route.fullPath;
      if (antiCollision == "/antiCollision") {
        window.location.reload();
      }
      var warn = this.$route.fullPath;
      if (warn == "/warn") {
        window.location.reload();
      }
    },
    exit() {
      this.$utils.clearItem();
      this.$router.push({ path: "/login" });
    },
  }
}
</script>

<style lang="scss" scoped>
.ctitle {
  position: fixed;
  top: 100px;
  left: 36px;
  font-size: 16px;
  z-index: 2222;
  color: #555;
}
.row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  .col {
    width: 660px;
    height: 340px;
    margin-bottom: 30px;
    background-color: rgba(255, 255, 255, 0.75);
  }
  .title {
    font-size: 18px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .chart {
    margin: 0 auto;
    width: 620px;
    height: 280px;
  }
  .time {
    padding: 10px 20px;
    text-align: center;
    font-size: 18px;
    color: #000;
    border-bottom: 1px solid #000;
  }
  .body {
    padding: 20px 40px;
    .item {
      height: 70px;
      .info {
        display: inline-flex;
        align-items: center;
        width: 50%;
        height: 70px;
        .glass {
          display: inline-block;
          width: 50%;
          font-size: 16px;
          color: #000;
        }
        .green {
          display: inline-block;
          width: 50%;
          font-size: 16px;
          color: #000;
        }
      }
    }
  }
  .clear {
    display: table;
    clear: both;
    content: "";
    width: 660px;
    min-height: 1px;
  }
  .compass-icon {
    width: 232px;
    height: 200px;
    background-image: url(@/assets/images/compass-icon.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin: 24px 20px 0 20px;
    position: relative;
    .pointer-icon {
      width: 33px;
      height: 100px;
      left: calc(50% - 17px);
      position: absolute;
      top: 0;
      transition-duration: .5s;
      transform-origin: bottom;
    }
    .compass-dir {
      color: #000;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%,50%);
    }
  }
  .compass-time {
    color: #000;
    font-size: 14px;
    padding-top: 10px;
    pointer-events: auto;
    text-align: center;
  }
  .compass {
    display: inline-block;
  }
  .compass-tit {
    color: #000;
    font-size: 16px;
    pointer-events: auto;
    text-align: center;
    font-weight: bold;
  }
}
.container {
  display: flex;
  flex-direction: column;
  padding-top: 150px;
  position: relative;
  z-index: 2000;
}
.header_l,
.header_r {
  z-index: 20000;
  position: fixed;
  top: 30px;
  height: 52px;
  background-color: #fff;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    padding-left: 18px;
    img {
      width: 226px;
    }
  }
  .tit {
    font-size: 24px;
    margin: 0 10px;
    font-family: cursive;
    font-weight: bold;
  }
  .nav {
    display: flex;
    align-items: center;
    justify-content: center;
    .item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 52px;
      border-radius: 30px;
      font-size: 16px;
      color: #2a3537;
      cursor: pointer;
      &.active {
        background-color: #3396de;
        color: #fff;
      }
    }
  }
}
.header_l {
  left: 30px;
}
.header_r {
  left: 900px;
  .tit {
    margin-left: 16px;
  }
}

.login-area {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 2000;
  display: flex;
  flex-direction: column;

  &:hover {
    .dropdown {
      display: inline-flex;
    }
  }

  .dropdown {
    display: none;
    justify-content: flex-start;
    flex-direction: column;
    margin: 10px auto 0;
    border-radius: 6px;
    padding: 6px;
    box-shadow: 0 3px 14px 2px rgba(0, 0, 0, .05), 0 8px 10px 1px rgba(0, 0, 0, 6%), 0 5px 5px -3px rgba(0, 0, 0, 10%);
    background-color: #fff;
    font-size: 14px;
    color: #2a3537;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -20px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom: 10px solid #fff;
    }

    .dropdown-item {
      padding: 4px 10px;
      text-align: center;
      cursor: pointer;
      &:hover {
        background-color: #f3f3f3;
        color: #3396de;
      }
    }
    .dropdown-horizontal {
      height: 0.5px;
      margin: 2px 0;
      background-color: #ccc;
    }
  }

  .user {
    background: #fff;
    padding: 10px 16px;
    border-radius: 22px;
    color: #2a3537;
  }
}

</style>