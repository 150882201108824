import "leaflet/dist/leaflet.css";
import "leaflet-velocity/dist/leaflet-velocity.css";
import  L from "leaflet";
import "./leaflet-popup-modifer.css";
// import "./leaflet-popup-modifier.js";
// import "leaflet-velocity/dist/leaflet-velocity";
import "./leaflet-velocity.js";

// 解决默认 maker 无法显示的问题
// import icon from "/images/Ellipse1085.png";
// import icon from "@/assets/images/Ellipse1085.png";
// import iconShadow from "leaflet/dist/images/marker-shadow.png";

// let DefaultIcon = L.icon({
//   popupAnchor: [7, 0],
//   iconUrl: icon
// });

// L.Marker.prototype.options.icon = DefaultIcon;

/**
 * 创建map对象
 * @param {*} divId 指定 id 的 div 元素
 * @param {*} options 选项
 * @returns map 对象
 */
 const createMap = (divId, options = {}) => {
    let map = L.map(divId, options);
    return map;
}

/**
 * 在地图上加载和显示瓦片图层
 * @param {*} map 地图对象
 * @param {*} url URL 模板
 * @param {*} options 选项
 */
const createTileLayer = async (map, url, options = {}) => {
    let tileLayer = await L.tileLayer(url, options);
    tileLayer.addTo(map);
    return tileLayer;
}

/**
 * 通过 [x, y] 坐标添加 Maker
 */
const createMakerByXY = (map, coordinate, options) => {
    /** 纬度 */
    let lat = coordinate[0],
    /** 经度 */
        lng = coordinate[1];
    let marker = L.marker(L.latLng(lat, lng), options);
    return marker;
}

/**
 * 创建 Maker 标记
 * @param {*} latLng 
 * @param {*} options 
 * @returns 
 */
const createMakerByLatlng = (latLng, options) => {
    return L.marker(latLng, options);
}

/**
 * 创建 popup 弹出窗口
 */
const createPopup = (options) => {
    // let popup = L.popup(options);
    let popup = L.customPopup(options);
    return popup;
}
/**
 * 创建 LayerGroup 图层组
 * @param {Array} layer Layer 图层数组
 * @param {*} options Options 选项
 * @returns 
 */
const createLayerGroup = (map, layer, options) => {
    var layerGroup = L.layerGroup(layer, options).addTo(map);
    return layerGroup;
}

const createPoint = (options) => {
    return L.point(options);
}

const createLayer = (data, map) => {
    var velocityLayer = L.velocityLayer({
        displayValues: true,
        displayOptions: {
          velocityType: "current",
          position: "bottomleft",
          emptyString: " "
        },
        data: data,
        lineWidth: 1.5,
        frameRate: 20, // 15
        minVelocity: 0,
        // 颜色比例
        maxVelocity: 0.6, // 0.6
        // 粒子动画的修饰器
        velocityScale: 0.04, // 0.1 arbitrary default 0.005
        // colorScale: ["rgb(255,255, 255)"],
        colorScale: ["rgb(36,104, 180)"],
        // colorScale: ['#1F263A', '#414AA9', '#44758C', '#399B58', 'DCD296', 'F2E899', 'A53E3C', '9C3333'],
        // colorScale: [
        //     '#313695',
        //     '#4575b4',
        //     '#74add1',
        //     '#abd9e9',
        //     '#e0f3f8',
        //     '#ffffbf',
        //     '#fee090',
        //     '#fdae61',
        //     '#f46d43',
        //     '#d73027',
        //     '#a50026'
        //   ],
        // colorScale: ["rgb(255,255,255)","rgb(51,150,222)"],
        // maxVelocity: 135,
        // particleAge: 200,
        particleMultiplier: 1 / 400,
      });
     
    velocityLayer.addTo(map);
    return velocityLayer;
}


const createDivIcon = (options) => {
    return L.divIcon(options)
}

export default {
    createMap: createMap,
    createTileLayer: createTileLayer,
    createMakerByXY: createMakerByXY,
    createMakerByLatlng: createMakerByLatlng,
    createPopup: createPopup,
    createLayerGroup: createLayerGroup,
    createPoint: createPoint,
    createLayer: createLayer,
    createDivIcon: createDivIcon
}