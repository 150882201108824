const months = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec'
}
export default {
    navName: {
        forecast: "Current Forecast",
        antiCollision: "Monitoring and Early Warning",
    },
    layer: {
        surface: "surface",
        middle: "middle",
        bottom: "bottom",
    },
    more: "view more",
    berthPrediction: "泊位预报",
    user: {
        name: "user",
        LogOut: 'Log out',
        reset: "Change password",
    },
    month: "month",
    day: "day",
    lng: "lng",
    lat: "lat",
    meter: "m",
    waterDepth: "water depth",
    chartTitle: "/ velocity / direction",
    marksDate: ({values}) =>  months[values.month] + " " + values.day,
    hour: "h",
    velocity: "velocity",
    direction: "direction",
    directArr: ({ values }) => {
        let arr = [
            "N", "NNE", "NE", "ENE",
            "E", "ESE", "SE", "SSE",
            "S", "SSW", "SW", "WSW",
            "W", "WNW", "NW", "NNW"
        ];
        return arr[values.index]
    },
    vsam: "VSAM",
    lidar: "Lidar",
    position: "Position",
    maxVelocity: "maximum flow rate",
    time: "time",
    highestWaterLevels: "highest water levels",
    lowestWaterLevels: "lowest water levels",
    information: "Alarm information",
    nextHours: "next 14 hours",
}